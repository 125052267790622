import React, { useEffect, useState, useRef, WheelEvent,useCallback } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, TooltipProps } from 'recharts';
import { Calendar, Eye, EyeOff } from 'lucide-react';
import dateUtils from './dateUtils';

// Enums
enum SemaphoreStatus {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray"
}

// Interfaces
interface TaskFromAPI {
  id: number;
  proceso: string;
  codigo: string;
  comuna: string;
  subproceso: string;
  subproceso_color: string;
  agrupador: string;
  nombre: string;
  responsable: string;
  fecha_inicio: string;
  fecha_termino: string;
  progreso: number;
  descriptor: string;
  organismo: string;
  duracion: number;
  dependencia: string[] | [];
  enabled: boolean;
  isClosed: boolean;
  comments: string;
  orden: number;
  followUpDate: string | null;
  semaphoreStatus: string;
  delay_in_days: number;
}

interface ChartDataPoint {
  date: string;
  vencidas: number;
  porVencer: number;
  proximasVencer: number;
  enTiempo: number;
  noIniciadas: number;
}

interface CustomTooltipProps extends TooltipProps<number, string> {
  active?: boolean;
  payload?: Array<{
    name: string;
    value: number;
    color: string;
    dataKey: string;
    payload: ChartDataPoint;
  }>;
  label?: string;
}

interface RangeOption {
  label: string;
  value: number;
}

interface ZoomState {
  startIndex: number;
  endIndex: number;
}

const RANGE_OPTIONS: RangeOption[] = [
  { label: '30 días', value: 30 },
  { label: '60 días', value: 60 },
  { label: '90 días', value: 90 },
];

const MIN_ZOOM_RANGE = 7;
const MAX_ZOOM_RANGE = 90;
const ZOOM_SENSITIVITY = 0.1;

// Custom Components
const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (!active || !payload || !label) return null;

  return (
    <div className="bg-white p-4 border rounded shadow-lg">
      <div className="flex items-center gap-2 mb-2">
        <Calendar className="w-4 h-4" />
        <p className="text-sm font-bold text-gray-700">
          {new Date(label).toLocaleDateString('es-CL', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}
        </p>
      </div>
      <div className="space-y-1">
        {payload.reverse().map((entry) => (
          <div
            key={entry.dataKey}
            className="flex items-center justify-between px-2 py-1 rounded"
            style={{ backgroundColor: `${entry.color}15` }}
          >
            <span className="text-sm" style={{ color: entry.color }}>
              {entry.name}
            </span>
            <span className="font-bold text-sm" style={{ color: entry.color }}>
              {entry.value} tareas
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};


const TaskProgressChart: React.FC = () => {
  const [taskData, setTaskData] = useState<ChartDataPoint[]>([]);
  const [displayData, setDisplayData] = useState<ChartDataPoint[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [totalTasks, setTotalTasks] = useState(0);
  const [showLegend, setShowLegend] = useState(true);
  const [zoom, setZoom] = useState(100); // Zoom inicial en 100%

const MIN_ZOOM_RANGE = 7;
const DEFAULT_RANGE = 90;
  const [selectedRange, setSelectedRange] = useState(DEFAULT_RANGE);
  const [zoomState, setZoomState] = useState<ZoomState>({
    startIndex: 0,
    endIndex: DEFAULT_RANGE
  });
  const [minDateIndex, setMinDateIndex] = useState(0);

  
  const chartRef = useRef<HTMLDivElement>(null);
  const calculateTaskStatus = (task: TaskFromAPI, currentDateStr: string): string => {
    if (task.isClosed) {
      return 'enTiempo';
    }
    

    const currentDate = dateUtils.parseLocalDate(currentDateStr);
    const startDate = dateUtils.parseLocalDate(task.fecha_inicio);
    const endDate = dateUtils.parseLocalDate(task.fecha_termino);

    // Si la fecha actual es anterior a la fecha de inicio
    if (currentDate < startDate) {
      return 'noIniciadas';
    }

    // Si la fecha actual es posterior a la fecha de término y no está cerrada
    if (currentDate > endDate) {
      return 'vencidas';
    }

    // Calcular días hasta el término
    const daysUntilEnd = dateUtils.calculateDaysDifference(task.fecha_termino, currentDateStr);

    if (daysUntilEnd <= 2) {
      return 'porVencer';
    }
    if (daysUntilEnd <= 5) {
      return 'proximasVencer';
    }

    return 'enTiempo';
  };

  const processTaskData = (tasks: TaskFromAPI[]): ChartDataPoint[] => {
    const today = dateUtils.getTodayLocal();
    let minDate = today;
    
    const activeTasks = tasks.filter(task => !(task.isClosed && task.progreso === 100));

    // Encontrar la fecha más temprana (hasta 365 días atrás)
    activeTasks.forEach(task => {
      const startDate = dateUtils.parseLocalDate(task.fecha_inicio);
      if (startDate < minDate) minDate = startDate;
    });

    // Limitar a 365 días hacia atrás desde hoy
    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);
    if (minDate < oneYearAgo) minDate = oneYearAgo;

    // Generar array de fechas hasta hoy
    const dateArray: ChartDataPoint[] = [];
    const currentDate = new Date(minDate);
    
    while (currentDate <= today) {
      const currentDateStr = dateUtils.formatLocalDate(currentDate);
      const dataPoint: ChartDataPoint = {
        date: currentDateStr,
        vencidas: 0,
        porVencer: 0,
        proximasVencer: 0,
        enTiempo: 0,
        noIniciadas: 0
      };

      // Calcular estado de cada tarea para esta fecha
      tasks.forEach(task => {
        const status = calculateTaskStatus(task, currentDateStr);
        dataPoint[status as keyof Omit<ChartDataPoint, 'date'>]++;
      });

      dateArray.push(dataPoint);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  };

  const handleRangeChange = (days: number) => {
    if (!taskData.length) return;
    
    const endIndex = taskData.length - 1; // Último día (hoy)
    const startIndex = Math.max(0, endIndex - days + 1); // Calculamos el inicio basado en los días
    
    setSelectedRange(days);
    setZoomState({ startIndex, endIndex });
    setDisplayData(taskData.slice(startIndex, endIndex + 1));
  };

  useEffect(() => {
    if (taskData.length > 0) {
        const { startIndex, endIndex } = zoomState;
        setDisplayData(taskData.slice(startIndex, endIndex + 1));
    }
}, [zoomState, taskData]);
  // Cambia el rango de fechas visibles según el desplazamiento del ratón
 // Maneja el scroll para ajustar el rango de fechas visibles en el gráfico
 const handleWheel = useCallback((e: WheelEvent) => {
  if (e.ctrlKey) {
    e.preventDefault();
    const currentRange = zoomState.endIndex - zoomState.startIndex;
    const delta = e.deltaY < 0 ? -10 : 10;
    const newRange = Math.max(MIN_ZOOM_RANGE, Math.min(MAX_ZOOM_RANGE, currentRange + delta));

    const endIndex = taskData.length - 1;
    const startIndex = Math.max(minDateIndex, endIndex - newRange);

    setZoomState({ startIndex, endIndex });
  }
}, [taskData, zoomState, minDateIndex]);

// Actualiza `displayData` basado en el `zoomState`
useEffect(() => {
  const { startIndex, endIndex } = zoomState;
  setDisplayData(taskData.slice(startIndex, endIndex + 1));
}, [zoomState, taskData]);

// Agregar y eliminar el evento `wheel` al contenedor
useEffect(() => {
  const chartElement = chartRef.current;

  const wheelHandler = (e: Event) => {
    const wheelEvent = e as unknown as WheelEvent;
    if (wheelEvent.ctrlKey) {
      wheelEvent.preventDefault();
      handleWheel(wheelEvent);
    }
  };

  if (chartElement) {
    chartElement.addEventListener('wheel', wheelHandler, { passive: false });
  }

  return () => {
    if (chartElement) {
      chartElement.removeEventListener('wheel', wheelHandler);
    }
  };
}, [handleWheel]);

  
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/adm_planificacion/get_all_tasks.php`);
        if (!response.ok) throw new Error('Error al cargar las tareas');
        const tasks: TaskFromAPI[] = await response.json();
        
        setTotalTasks(tasks.length);
        const processedData = processTaskData(tasks);
        setTaskData(processedData);
        const minDate = new Date(Math.min(...tasks.map(task => new Date(task.fecha_inicio).getTime())));
        const minDateIndex = processedData.findIndex(point => new Date(point.date).getTime() >= minDate.getTime());

        setMinDateIndex(minDateIndex);
        // Inicializar con el rango por defecto (90 días)
        const endIndex = processedData.length - 1;
        const startIndex = Math.max(minDateIndex, endIndex - 89);

        setZoomState({ startIndex, endIndex });
        setSelectedRange(90);
        setDisplayData(processedData.slice(startIndex, endIndex + 1));
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Error desconocido');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTasks();
  }, []);


  if (isLoading) return (
    <div className="w-full h-96 flex items-center justify-center bg-white rounded-lg shadow">
      <div className="flex flex-col items-center gap-2">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-teal-500" />
        <p className="text-teal-600">Cargando datos...</p>
      </div>
    </div>
  );

  if (error) return (
    <div className="w-full h-96 flex items-center justify-center bg-white rounded-lg shadow text-red-500">
      {error}
    </div>
  );
  const calculatePercentage = (value: number) => {
    return ((value / totalTasks) * 100).toFixed(1);
  };
  return (
    <div className="w-full bg-white rounded-xl shadow-lg p-6 transition-all overflow-hidden">
      <div className="mb-6 space-y-6">
        <div className="flex items-center justify-between border-b border-gray-200 pb-4">
          <div className="flex items-center gap-3">
            <div className="h-8 w-2 "></div>
          {/*  <h2 className="text-2xl font-extrabold text-teal-500">
              Progreso de Tareas
            </h2>*/}
            <div className="flex flex-wrap gap-2">
          {RANGE_OPTIONS.map((option) => (
            <button
              key={option.value}
              onClick={() => handleRangeChange(option.value)}
              className={`
                px-4 py-2 rounded-lg text-sm font-semibold transition-all
                shadow-sm hover:shadow transform hover:-translate-y-0.5
                ${selectedRange === option.value
                  ? 'bg-teal-500 text-white ring-2 ring-teal-500 ring-offset-2'
                  : 'bg-white text-teal-500 border-2 border-teal-200 hover:border-teal-500'
                }
              `}
            >
              {option.label}
            </button>
          ))}
        </div>
          </div>
          <button
            onClick={() => setShowLegend(!showLegend)}
            className="p-2 hover:bg-teal-50 rounded-full transition-colors text-teal-500"
          >
            {showLegend ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
          </button>
        </div>

       
      </div>

      <div 
        className="relative h-96" 
        ref={chartRef}
        onWheel={(e) => handleWheel(e as WheelEvent)} 
      >
        <ResponsiveContainer>
        <AreaChart data={displayData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>

            <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
            <XAxis
              dataKey="date"
              tickFormatter={(date: string) => {
                const parsedDate = dateUtils.parseLocalDate(date);
                return parsedDate.toLocaleDateString('es-CL', {
                  day: '2-digit',
                  month: 'short'
                });
              }}
              stroke="#6B7280"
              tick={{ fontSize: 12 }}
            />
            <YAxis 
              domain={[0, totalTasks]}
              tickFormatter={(value) => `${((value/totalTasks) * 100).toFixed(0)}%`}
              stroke="#6B7280"
              tick={{ fontSize: 12 }}
            />
            <Tooltip
              content={({ active, payload, label }) => {
                if (!active || !payload || !label) return null;
                
                return (
                  <div className="bg-white p-4 border rounded-lg shadow-lg">
                    <div className="flex items-center gap-2 mb-3 pb-2 border-b">
                      <Calendar className="w-4 h-4 text-gray-600" />
                      <p className="text-sm font-bold text-gray-700">
                        {dateUtils.parseLocalDate(label).toLocaleDateString('es-CL', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </p>
                    </div>
                    <div className="space-y-2">
                      {payload.map((entry: any) => (
                        <div
                          key={entry.dataKey}
                          className="flex items-center justify-between px-3 py-1.5 rounded-md"
                          style={{ backgroundColor: `${entry.color}10` }}
                        >
                          <span className="text-sm" style={{ color: entry.color }}>
                            {entry.name}
                          </span>
                          <span className="font-bold text-sm" style={{ color: entry.color }}>
                            {((entry.value/totalTasks) * 100).toFixed(1)}%
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              }}
            />
            
            <Area
              type="monotone"
              dataKey="vencidas"
              stackId="1"
              stroke="#EF4444"
              fill="#EF4444"
              name="Vencidas"
            />
            <Area
              type="monotone"
              dataKey="porVencer"
              stackId="1"
              stroke="#F59E0B"
              fill="#F59E0B"
              name="Por Vencer"
            />
            <Area
              type="monotone"
              dataKey="proximasVencer"
              stackId="1"
              stroke="#FBBF24"
              fill="#FBBF24"
              name="Próximas a Vencer"
            />
            <Area
              type="monotone"
              dataKey="enTiempo"
              stackId="1"
              stroke="#34D399"
              fill="#34D399"
              name="En Tiempo"
            />
            <Area
              type="monotone"
              dataKey="noIniciadas"
              stackId="1"
              stroke="#9CA3AF"
              fill="#9CA3AF"
              name="No Iniciadas"
            />
          </AreaChart>
        </ResponsiveContainer>

        <div 
          className={`
            absolute top-2 right-2 bg-white/95 backdrop-blur-sm rounded-lg shadow-lg
            transform transition-all duration-300 ease-in-out
            ${showLegend ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}
          `}
        >
          <div className="p-4 space-y-3">
            {displayData.length > 0 && [
              { label: 'No Iniciadas', value: displayData[displayData.length - 1].noIniciadas, color: '#9CA3AF' },
              { label: 'En Tiempo', value: displayData[displayData.length - 1].enTiempo, color: '#34D399' },
              { label: 'Próximas a Vencer', value: displayData[displayData.length - 1].proximasVencer, color: '#FBBF24' },
              { label: 'Por Vencer', value: displayData[displayData.length - 1].porVencer, color: '#F59E0B' },
              { label: 'Vencidas', value: displayData[displayData.length - 1].vencidas, color: '#EF4444' }
            ].map((item) => (
              <div key={item.label} className="flex items-center justify-between gap-4 text-sm">
                <div className="flex items-center gap-2">
                  <div
                    className="w-3 h-3 rounded"
                    style={{ backgroundColor: item.color }}
                  />
                  <span className="font-medium text-gray-700">{item.label}</span>
                </div>
                <span className="font-bold text-gray-900">
                  {((item.value/totalTasks) * 100).toFixed(1)}%
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskProgressChart;