import React, { useState, useEffect } from 'react';
import '../../App.css'; // Asegúrate de importar el archivo CSS

const InviteUser: React.FC = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState(''); // Nuevo estado para el nombre
    const [isAdmin, setIsAdmin] = useState(false);
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false); // Estado de carga
    const [userAuthorized, setUserAuthorized] = useState<boolean>(false);

    useEffect(() => {
        // Verifica el estado del usuario al cargar el componente
        const adminStatus = sessionStorage.getItem('isAdmin');
        if (adminStatus === 'true') {
            setUserAuthorized(true);
            setIsAdmin(true);
        } else {
            setUserAuthorized(false);
        }
    }, []);

    const handleInvite = async () => {
        setMessage(null);
        setError(null);
        setLoading(true); // Activar el spinner

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/php/login/send_invitation.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, name, is_admin: isAdmin }), // Incluir nombre en la solicitud
            });

            const data = await response.json();

            if (data.success) {
                setMessage('Invitación enviada exitosamente.');
                setName('');
                setEmail('');
                setIsAdmin(false);
            } else {
                setError(data.error);
            }
        } catch (error) {
            setError(`Error en la solicitud. ${error}`);
        } finally {
            setLoading(false); // Desactivar el spinner
        }
    };

    if (!userAuthorized) {
        return <p>No tienes permiso para ver esta página.</p>;
    }

    return (
        <div className="">
            {//<h2>Invitar Usuario</h2>
}
            <p className="description mb-7 text-sm ">
                Utiliza este formulario para invitar a nuevos usuarios a tu sistema. Puedes especificar si el usuario será un administrador.
            </p>
            <div className="input-field text-sm ">
                <label htmlFor="name">Nombre</label>
                <input
                    type="text"
                    className='text-sm '
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Ingrese el nombre del invitado"
                />
            </div>
            <div className="input-field">
                <label htmlFor="email">Correo electrónico</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Ingrese el correo electrónico"
                />
            </div>
            <div className="input-field">
                <label>
                    <input
                        type="checkbox"
                        checked={isAdmin}
                        onChange={(e) => setIsAdmin(e.target.checked)}
                    />
                    Administrador
                </label>
            </div>
            <button className="submit-button" onClick={handleInvite} disabled={loading}>
                {loading ? <div className="spinner-container">
                        <div className="spinner"></div>
                        <span>Enviando mensaje...</span>
                    </div> : 'Enviar Invitación'}
            </button>
            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">Error: {error}</p>}
        </div>
    );
};

export default InviteUser;
