import React, { FocusEvent, useRef, useState, useEffect,ChangeEvent, DragEvent, useCallback} from 'react';
import { Download, Edit, Trash, Eye, Flag, PlusCircle, MinusCircle, File, Paperclip, Split, Mail, X } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { match } from 'assert';

interface InputFieldProps {
  label: string;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  [x: string]: any; // Para aceptar otras propiedades del input
}

interface Option {
  id: number;
  label: string;
}

interface ComboBoxProps {
  label: string;
  options: string[] | Option[] | Subproceso[];
}

const ComboBox: React.FC<ComboBoxProps> = ({ label, options }) => {
  // Función para determinar si options es un array de objetos
  const isArrayOfObjects = (
    options: string[] | Option[] | Subproceso[]
  ): options is Option[] | Subproceso[] => {
    return (
      options.length > 0 &&
      typeof options[0] === 'object' &&
      ('id' in options[0]) &&
      ('label' in options[0] || 'subproceso' in options[0])
    );
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
      <select className="w-full border border-gray-300 rounded-md p-2 focus:ring-cyan-500 focus:border-cyan-500">
        <option value="">Seleccione una opción</option>
        {isArrayOfObjects(options)
          ? options.map((option) =>
              'label' in option ? (
                <option key={option.id} value={option.id}>
                  {option.label}
                </option>
              ) : (
                <option key={option.id} value={option.id}>
                  {option.subproceso}
                </option>
              )
            )
          : options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
      </select>
    </div>
  );
};






const InputField: React.FC<InputFieldProps> = ({
  label,
  className,
  inputClassName,
  disabled,
  ...props
}) => (
  <div className={className}>
    <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
    <input 
      type="text"
      disabled={disabled}
      className={`w-full border border-gray-300 rounded-md p-2 focus:ring-cyan-500 focus:border-cyan-500 ${inputClassName} ${disabled ? 'bg-gray-100 cursor-not-allowed' : ''}`}
      {...props} 
    />
  </div>
);


interface Subproceso {
  id: number;
  subproceso: string;
}

interface SubprocesoSelectProps {
  processId: number; // ID del proceso para obtener los subprocesos
}

interface TransmittalModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  documents: Document[]; // Documentos que se pasan al modal
}

const TransmittalModal: React.FC<TransmittalModalProps> = ({ isOpen, onClose, onSubmit, documents }) => {
  const [projectFilter, setProjectFilter] = useState('');
  const [projectOptions, setProjectOptions] = useState<string[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);

  const [codigo, setCodigo] = useState<string[]>([]);
  const [nombreProceso, setNombreProceso] = useState<string[]>([]);
  const [comuna, setComuna] = useState<string[]>([]);
  const [selectedNombreProyecto, setSelectedNombreProyecto] = useState<string>('');
  const [selectedComuna, setSelectedComuna] = useState<string>('');
  const [processFilter, setProcessFilter] = useState<string>('');
  const [processOptions, setProcessOptions] = useState<string[]>([]);
  const [processes, setProcesses] = useState<string[]>([]);
  const [username, setUsername] = useState<string | null>(null);
  const [emisor, setEmisor] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userTitle, setUserTitle] = useState<string | null>(null);
  const [transmittalNumber, setTransmittalNumber] = useState<string>('');
  const [nombreProyecto, setNombreProyecto] = useState<string>('');
  const [codigoProyecto, setCodigoProyecto] = useState<string>('');


  const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
  const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState<string>('');
  const [selectedSubproceso, setSelectedSubproceso] = useState<string>('');


  const [codigoTransmittal, setCodigoTransmittal] = useState<string>('');

  const [toEmail, setToEmail] = useState('');
  const [ccEmail, setCcEmail] = useState('');
  
  const [niveles, setNiveles] = useState<Nivel[]>([]);
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});

  const [message, setMessage] = useState<{ text: string, type: 'success' | 'error' } | null>(null);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Nuevos estados para los campos del formulario
  const [asunto, setAsunto] = useState<string>('');
  const [referencia, setReferencia] = useState<string>('');
  const [descripcion, setDescripcion] = useState<string>('');
  const [subcarpeta, setSubcarpeta] = useState<string>('');
  const [detalleTransmittal, setDetalleTransmittal] = useState<{ [key: string]: boolean }>({
    Preliminar: false,
    'Para Información': false,
    'Para Coordinación': false,
    'Para Aprobación': false,
  });
  const [comentarios, setComentarios] = useState<string>('');
  const [data, setData] = useState<Data[]>([]);
/*
  const emailOptions = [
    { name: 'Francis Serrano', email: 'f.serrano@correo.cl' },
    { name: 'Ana Pérez', email: 'ana.perez@correo.cl' },
    { name: 'Luis Martínez', email: 'luis.martinez@correo.cl' },
    // Agrega más opciones según sea necesario
  ];*/
  // Sort states
  const [emailOptions, setEmailOptions] = useState<EmailOption[]>([]);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');



  const handleAsuntoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAsunto(e.target.value);
  };

  const handleReferenciaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReferencia(e.target.value);
  };

  const handleDescripcionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescripcion(e.target.value);
  };

  const handleSubprocesoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;
    const selectedSubproceso = subprocesos.find(sub => sub.id.toString() === selectedId);
    
    if (selectedSubproceso) {
      setSelectedSubproceso(selectedSubproceso.subproceso); // O cualquier estado en el que quieras guardar el nombre
    }
  };

  const handleComentariosChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComentarios(e.target.value);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setDetalleTransmittal(prevState => ({
      ...prevState,
      [id]: checked
    }));
  };


  useEffect(() => {
    const shouldDisableButton = 
      !processFilter || 
      !toEmail || 
      !asunto || 
      !selectedSubproceso;
  
    setIsButtonDisabled(shouldDisableButton);
  }, [processFilter, toEmail, asunto, selectedSubproceso]);
  



  

  useEffect(() => {
    // Reset all fields when the modal is closed
    if (!isOpen) {
      //setProjectFilter('');
     // setProjectOptions([]);
     // setSelectedDocuments([]);
      //setCodigo([]);
      //setNombreProceso([]);
     // setComuna([]);
      setSelectedNombreProyecto('');
      setSelectedCodigoProyecto('');
      setSelectedComuna('');
      setProcessFilter('');
     setIsButtonDisabled(false);
     setIsLoading(false);
     // setProcesses([]);
     // setUsername(null);
     // setTransmittalNumber('');
     // setCodigoProyecto('');
      setCodigoTransmittal('');
      setToEmail('');
      setCcEmail('');
    //  setNiveles([]);
      //setSelectedValues({});
      setAsunto('');
      setReferencia('');
      setDescripcion('');
      setSelectedSubproceso('');
      setDetalleTransmittal({
        Preliminar: false,
        'Para Información': false,
        'Para Coordinación': false,
        'Para Aprobación': false,
        'Para Construcción': false,
      });
      setComentarios('');
      //setData([]);
    //  setEmailOptions([]);
      setSortColumn(null);
      setSortDirection('asc');
      setMessage(null);
    }
  }, [isOpen]);



  useEffect(() => {
    // Función para obtener los datos desde el backend
    const fetchEmails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_emails.php`);
        setEmailOptions(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error al obtener correos:', error);
      }
    };

    fetchEmails();
  }, []);


  useEffect(() => {
    fetchProcesses();
    fetchNextTransmittalNumber();
  }, []);

  useEffect(() => {
    const storedFirstName = sessionStorage.getItem('firstName');
    const storedLastName = sessionStorage.getItem('lastName');
    const storedEmail = sessionStorage.getItem('email');
    const storedTitle = sessionStorage.getItem('title');
    
    
    if (storedFirstName && storedLastName) {
      setUsername(`${storedFirstName} ${storedLastName}`);
      setEmisor(`${storedFirstName} ${storedLastName} <${storedEmail}>`);
    }
     
    if (storedEmail) {
      setUserEmail(`${storedEmail} `);
    }
     
    if (storedTitle ) {
      setUserTitle(`${storedTitle}`);
    }

  }, []);

  useEffect(() => {
    if (transmittalNumber && selectedCodigoProyecto) {
      setCodigoTransmittal(`${selectedCodigoProyecto}-P-TRL-AI-${transmittalNumber}`);
    }
  }, [transmittalNumber, selectedCodigoProyecto]);

  useEffect(() => {
    const fetchSubprocesos = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_subprocesos.php?codigo=${selectedCodigoProyecto}`);
        const data = await response.json();

        if (data && typeof data === 'object' && Array.isArray(data.subprocesos)) {
          setSubprocesos(data.subprocesos);
          console.log(data);
        } else {
          console.error('Error al obtener los subprocesos', data);
        }
      } catch (error) {
        console.error('Error de red', error);
      }
    };

    if (selectedCodigoProyecto.length > 0) {
      fetchSubprocesos();
    }
  }, [selectedCodigoProyecto]);


  const fetchNextTransmittalNumber = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/get_next_transmittal_number.php`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setTransmittalNumber(data.transmittal_number.toString().padStart(2, '0'));
    } catch (error) {
      console.error('Error fetching next transmittal number:', error);
    }
  };




  const handleSubmit = async () => {

    setIsLoading(true);
    setIsButtonDisabled(true);



    const detalleArray = Object.keys(detalleTransmittal).map(
      key => detalleTransmittal[key]
    );
  
    try {
      
      
      
      await saveTransmittal(); // Asume que saveTransmittal es la función que realiza el guardado
  
      setMessage({
        text: 'Transmittal enviado exitosamente.',
        type: 'success'
      });
  
      // Cierra el modal después de 3 segundos
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      setMessage({
        text: 'Error al enviar el transmittal.',
        type: 'error'
      });
      setIsLoading(false);
      setIsButtonDisabled(false);
    }
  };

   // Extraer IDs de los documentos
   const extractDocumentIds = (docs: { id: string }[]) => {
    return docs.map(doc => doc.id);
};





  const handlePreview = async () => {
 
    try {
      //alert(selectedSubproceso)

        // Extrae los IDs de los documentos
        const documentIds = extractDocumentIds(documents);
      //  alert(documentIds);
         // Solicitar URLs de documentos
         const responseDownloads = await axios.post(
          `${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/download_documents.php?type=type2`,
          { documentIds },
          { responseType: 'json' } // Esperar JSON con URLs en lugar de blob
      );
      
      const documentUrls = responseDownloads.data.url; // Asume que `urls` es el campo en la respuesta
   
      // Mostrar URLs en una alerta o en la consola
      console.log('Document URLs:', documentUrls);
    //  alert('Document URLs have been logged in the console.');
  
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/generar_transmital.php?type=type1`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          transmittal_number: parseInt(transmittalNumber, 10),
          codigo_proyecto: selectedCodigoProyecto,
          nombre_proyecto: selectedNombreProyecto,
          comuna: selectedComuna,
          emisor: username,
          emisorCorreo: userEmail,
          emisorTitle: userTitle,
          destinatarios: toEmail,
          cc: ccEmail,
          asunto: asunto,
          referencia,
          descripcion,
         // subcarpeta: selectedValues[1],
          subproceso: selectedSubproceso,
          detalle_transmittal: detalleTransmittal,
          documentos_seleccionados: currentDocuments,
          zipUrl: documentUrls,
          comentarios
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const blob = await response.blob();
      const pdfUrl = URL.createObjectURL(blob);
      window.open(`${pdfUrl}`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  
  const saveTransmittal = async () => {
    try {

      
        // Extrae los IDs de los documentos
        const documentIds = extractDocumentIds(documents);
      //  alert(documentIds);
         // Solicitar URLs de documentos
         const responseDownloads = await axios.post(
          `${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/download_documents.php?type=type2`,
          { documentIds },
          { responseType: 'json' } // Esperar JSON con URLs en lugar de blob
      );
      
      const documentUrls = responseDownloads.data.url; // Asume que `urls` es el campo en la respuesta
   
      // Mostrar URLs en una alerta o en la consola
      console.log('Document URLs:', documentUrls);
    //  alert('Document URLs have been logged in the console.');
  
 


    // Enviar datos para generar el transmittal
    const responseFileTRL = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/generar_transmital.php?type=type2`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        transmittal_number: parseInt(transmittalNumber, 10),
        codigo_proyecto: selectedCodigoProyecto,
        nombre_proyecto: selectedNombreProyecto,
        comuna: selectedComuna,
        emisor: username,
        emisorCorreo: userEmail,
        emisorTitle: userTitle,
        destinatarios: toEmail,
        cc: ccEmail,
        asunto: asunto,
        referencia,
        descripcion,
        //subcarpeta: selectedValues[1],
        subproceso: selectedSubproceso,
        detalle_transmittal: detalleTransmittal,
        documentos_seleccionados: currentDocuments,
        zipUrl: documentUrls,
        comentarios
      })
    });

    if (!responseFileTRL.ok) {
      throw new Error(`HTTP error! Status: ${responseFileTRL.status}`);
    }

    // Obtener la URL del archivo PDF generado
    const fileTRLResponse = await responseFileTRL.json();

    if (fileTRLResponse.pdfUrl) {
      console.log('URL del archivo PDF generado:', fileTRLResponse.pdfUrl);
    } else {
      console.error('No se recibió una URL de PDF en la respuesta.');
    }


    const transmittalLink = fileTRLResponse.pdfUrl;
    //alert(selectedSubproceso);
  
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/transmittal/save_transmittal.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        transmittal_number: parseInt(transmittalNumber, 10),
        pdfUrl: transmittalLink,
        codigo_proyecto: selectedCodigoProyecto,
        nombre_proyecto: selectedNombreProyecto,
        comuna: selectedComuna,
        emisor: username,
        destinatarios: toEmail,
        cc: ccEmail,
        asunto:asunto,
        referencia,
        descripcion,
        subproceso: selectedSubproceso,
        detalle_transmittal: detalleTransmittal,
        documentos_seleccionados: currentDocuments,
        zipUrl: documentUrls,
        comentarios
      })
    });

      const data = await response.json();

      if (data.success) {
        console.log('Transmittal guardado con éxito:', data.transmittal_code);
      } else {
        console.error('Error al guardar el transmittal:', data.error);
      }
    } catch (error) {
      console.error('Error de red:', error);
    }
  };


  const fetchProcesses = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type4`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const nombresProcesos = data.map((item: { nombreProceso: string }) => item.nombreProceso);
      setProcesses(nombresProcesos);
      const codigosProyecto = data.map((item: { codigo: string }) => item.codigo);
      const nombres = data.map((item: { nombreProceso: string }) => item.nombreProceso);
      const comunas = data.map((item: { comuna: string }) => item.comuna);

      setCodigoProyecto(codigosProyecto);
      setNombreProyecto(nombres);
      setNombreProceso(nombres);
      setComuna(comunas);
    } catch (error) {
      console.error('Error fetching processes:', error);
    }
  };

  const handleProjectFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setProcessFilter(selectedValue);
    
    const index = nombreProceso.indexOf(selectedValue);
    if (index !== -1) {
      //alert(nombreProceso[index])
      // Asegúrate de que el índice esté dentro del rango válido
      setSelectedNombreProyecto(nombreProceso[index] || '');
      setSelectedCodigoProyecto(codigoProyecto[index] || '');
      setSelectedComuna(comuna[index] || '');
    } else {
      setSelectedNombreProyecto('');
      setSelectedCodigoProyecto('');
      setSelectedComuna('');
    }
  };
  

  const handleSort = (column: string) => {
    setSortDirection(sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc');
    setSortColumn(column);
  };


  const handleSelectChange = (nivelId: number, value: string) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [nivelId]: value,
      
      
    }));

};

 // Function to get options based on nivel_id
    const getOptionsForNivel = (nivelId: number) => {
      return data
        .filter(item => item.nivel_id === nivelId)
        .map(item => (
          <option key={item.concepto} value={item.nomenclatura}>
            {`${item.nomenclatura} - ${item.concepto}`}
          </option>
        ));
    };




  const toggleDocumentSelection = (id: string) => {
    setSelectedDocuments(prev =>
      prev.includes(id) ? prev.filter(docId => docId !== id) : [...prev, id]
    );
  };

  /*const saveTransmittal = async (
    transmittal_number: number,
    codigo_proyecto: string,
    comuna: string,
    asunto: string,
    referencia: string,
    descripcion: string
  ) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/transmittal/save_transmittal.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          transmittal_number,
          codigo_proyecto,
          comuna,
          asunto,
          referencia,
          descripcion
        })
      });

      const data = await response.json();

      if (data.success) {
        console.log('Transmittal guardado con éxito:', data.transmittal_code);
      } else {
        console.error('Error al guardar el transmittal:', data.error);
      }
    } catch (error) {
      console.error('Error de red:', error);
    }*/




  const viewFile = (path: string) => {
    
    window.open(`${process.env.REACT_APP_API_URL}/uploads/${path}`, '_blank'); // Abre el archivo en una nueva pestaña
  };


  useEffect(() => {
    // Fetch handleselec from the backend
    const fetchNiveles = async () => {
      try {
        const response = await axios.get<Nivel[]>(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/get_levels.php`);
        setNiveles(response.data);
        
      } catch (error) {
        console.error('Error fetching niveles:', error);
      }
    };

    const fetchData = async () => {
      try {
        const response = await axios.get<Data[]>(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/get_levels_data.php`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchNiveles();
    fetchData();
  }, []);



  const getRowClassName = (id: string) => {
    return selectedDocuments.includes(id) ? 'bg-blue-100' : '';
  };

  const currentDocuments = [...documents]; // Puedes aplicar filtros o manipular documentos si es necesario

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className="">
      <DialogContent className="max-h-screen mx-auto pr-12 pl-12 pt-7 overflow-y-auto bg-white rounded-lg">
       
        <h1 className="text-2xl font-bold mb-4 text-cyan-700">Formulario de Transmittal</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
          <InputField 
            label="N° Transmittal" 
            placeholder="N° Transmittal" 
            disabled
            value={transmittalNumber} 
            readOnly 
            className="text-sm"
          />
          
          <div>
            <label htmlFor="processFilter" className="block text-sm font-medium text-gray-700 mb-2">Proceso</label>
            <select
              id="processFilter"
              className="w-full p-2 border border-gray-300 rounded shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={processFilter}
              onChange={handleProjectFilterChange}
              aria-label="Filtro de Procesos"
            >
              <option value="">Seleccionar proceso</option>
              {processes.length > 0 ? (
                processes.map((process) => (
                  <option key={process} value={process}>{process}</option>
                ))
              ) : (
                <option disabled>No hay procesos disponibles</option>
              )}
            </select>
          </div>
          
          <InputField 
            label="Código Transmittal" 
            placeholder="Código Transmittal" 
            value={codigoTransmittal} 
            readOnly 
            disabled
            className="text-sm"
          />
          
          <InputField 
            label="Código de Proyecto" 
            placeholder="Código Proyecto" 
            value={selectedCodigoProyecto} 
            readOnly 
            disabled
            className="text-sm"
          />
          <InputField 
            label="Comuna" 
            placeholder="Comuna" 
            disabled
            value={selectedComuna} 
            readOnly 
            
            className="text-sm cursor-not-allowed"
          />
     
        </div>
        <br />
        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2 text-cyan-600">Destinatarios</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <InputField label="De:" value={emisor} disabled readOnly className="text-sm" />
        
          </div>
        </div>

        <div className='mb-5'>
        <AutoCompleteEmailInput
        label="Para:"
        emailOptions={emailOptions}
        onChange={setToEmail}
      />
      </div>
      <div className='mb-5'>
      <AutoCompleteEmailInput
        label="CC:"
        emailOptions={emailOptions}
        onChange={setCcEmail}
      />
      </div>

        <InputField 
          label="Asunto" 
          placeholder="Asunto" 
          className="mb-4 text-sm"
          value={asunto}
          onChange={handleAsuntoChange}
          inputClassName="w-full border-2 border-cyan-500 rounded-md p-2 focus:ring-2 focus:ring-cyan-500"
        />
        
      {/*  <InputField 
          label="Referencia" 
          placeholder="Referencia" 
          className="mb-4 text-sm"
          value={referencia}
          onChange={handleReferenciaChange}
          inputClassName="w-full border-2 border-cyan-500 rounded-md p-3 focus:ring-2 focus:ring-cyan-300 focus:border-cyan-500 transition duration-300"
        />
        
        <div className="mb-8">
          <label className="block text-sm font-medium text-gray-700 mb-2">Descripción Transmittal</label>
          <textarea 
            className="w-full border-2 border-cyan-500 rounded-md p-3 focus:ring-2 focus:ring-cyan-300 focus:border-cyan-500 transition duration-300" 
            rows={4} 
            placeholder="Descripción"
            value={descripcion}
            onChange={handleDescripcionChange}
          ></textarea>
        </div>*/}
 
            <div className='grid grid-cols-1 block '>
            <h2 className="text-xl font-semibold  text-cyan-600">Subproceso</h2>
            <select
        className="w-full border border-gray-300 rounded-md p-2 focus:ring-cyan-500 focus:border-cyan-500"
        onChange={handleSubprocesoChange}
        value={subprocesos.find(sub => sub.subproceso === selectedSubproceso)?.id || ''}
      >
          <option value="">Seleccione un subproceso</option>
        {subprocesos.map((subproceso) => (
          <option key={subproceso.id} value={subproceso.id}>
            {subproceso.subproceso}
          </option>
        ))}
      </select>
           
        {/*niveles.slice(0,1).map(nivel => (
          <div key={nivel.nivel_id} className='mb-6'>
            <label htmlFor={`select-${nivel.nivel_id}`} className="text-xl font-semibold mb-6 text-cyan-600 ">
              {nivel.titulo}
            </label>
            <select
              id={`select-${nivel.nivel_id}`}
              
              value={selectedValues[nivel.nivel_id] || ''}
              onChange={(e) => handleSelectChange(nivel.nivel_id, e.target.value)}
             
              className="p-2 border border-gray-300 rounded w-full"
            >
              <option value="">Seleccione {nivel.titulo}</option>
              {getOptionsForNivel(nivel.nivel_id)}
            </select>
          </div>
        ))*/}
        
          

        <div className="mb-8 mt-5">
          <h2 className="text-xl font-semibold mb-4 text-cyan-600">Detalles Transmittal</h2>
          <div className="bg-gray-50 p-4 rounded-md">
            {['Preliminar', 'Para Información', 'Para Coordinación', 'Para Aprobación'].map((item) => (
              <div key={item} className="flex items-center justify-between py-2 border-b last:border-b-0">
                <div className="flex items-center">
                <input 
                type="checkbox" 
                id={item} 
                className="mr-2" 
                checked={detalleTransmittal[item]}
                onChange={handleCheckboxChange} 
              />
                  <label htmlFor={item}>{item}</label>
                </div>
                {/*item.includes('Aprobación') || item === 'Archivo Documentación' ? (
                  <input type="date" className="border rounded-md p-1" />
                ) : (
                  <span>NA</span>
                )*/}
              </div>
            ))}
          </div>
        </div>
        </div>



        <div className="overflow-x-auto">
        <h2 className="text-xl font-semibold mb-4 text-cyan-600">Documentos seleccionados</h2>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Documento</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <button onClick={() => handleSort('title')} className="uppercase flex items-center">
                    Título
                    {sortColumn === 'title' ? (sortDirection === 'asc' ? ' 🔼' : ' 🔽') : ''}
                  </button>
                </th>
                
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Emitido Por</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Comentario</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Documento</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentDocuments.sort((a, b) => {
                if (sortColumn) {
                  const aVal = a[sortColumn as keyof Document] || '' ?.toString().toLowerCase();
                  const bVal = b[sortColumn as keyof Document] || '' ?.toString().toLowerCase();
                  if (aVal < bVal) return sortDirection === 'asc' ? -1 : 1;
                  if (aVal > bVal) return sortDirection === 'asc' ? 1 : -1;
                }
                return 0;
              }).map((doc) => (
                <tr key={doc.id} className={getRowClassName(doc.id)}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${doc.document}-REV${doc.revision}-v${doc.version}`}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{doc.title}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{doc.date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{doc.issuedBy}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{doc.comment}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                  <button className='text-gray-500' onClick={() => viewFile(doc.path)}>
                      <Paperclip />
                  
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        
        <div className="mt-8">
          <label className="block text-sm font-medium text-gray-700 mb-2">Comentarios</label>
          <textarea 
            className="w-full border-2 border-cyan-500 rounded-md p-3 focus:ring-2 focus:ring-cyan-300 focus:border-cyan-500 transition duration-300" 
            rows={4} 
            value={comentarios}
            onChange={handleComentariosChange}
            placeholder="Ingrese sus comentarios aquí"
          ></textarea>
        </div>

        {!message && (
       <div className="mt-6 flex items-center justify-between">
       <div className="flex-grow">
         <Button 
           onClick={onClose} 
           className="bg-teal-900 hover:bg-teal-700 text-white"
         >
           Cancelar
         </Button>
       </div>
       <div className="flex space-x-4">
         <Button 
          disabled={isButtonDisabled}
           className="bg-teal-500 hover:bg-teal-700 text-white font-semibold py-2 px-4 rounded"
           onClick={async (event: React.MouseEvent<HTMLButtonElement>) => {
             event.preventDefault();
             try {
               const documentIds = ['1', '2', '3']; // IDs de ejemplo
               await handlePreview();
             } catch (error) {
               console.error('Error:', error);
             }
           }}
         >
           Previsualizar
         </Button>
         <Button 
           onClick={handleSubmit} 
           disabled={isLoading || isButtonDisabled}
           className="bg-teal-500 hover:bg-teal-700 text-white font-semibold py-2 px-4 rounded"
         >
            {isLoading ? (
        <>
          <svg
            className="animate-spin h-5 w-5 mr-3 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
          Enviando...
        </>
      ) : (
        'Guardar y enviar'
      )}
         </Button>
       </div>
     </div>
       )}
         {/* Mostrar el mensaje */}
    {message && (
      <div
        className={`mt-4 p-2 text-white text-center rounded ${
          message.type === 'success' ? 'bg-green-300 text-green-700' : 'bg-red-300 text-red-800'
        }`}
      >
        {message.text}
      </div>
    )}
      </DialogContent>
    </Dialog>
  );
};


interface EmailOption {
  name: string;
  email: string;
}

interface AutoCompleteEmailInputProps {
  label: string;
  emailOptions: EmailOption[];
  onChange: (value: string) => void;
}



const AutoCompleteEmailInput: React.FC<AutoCompleteEmailInputProps> = ({ label, emailOptions, onChange }) => {
  const [query, setQuery] = useState('');
  const [filteredEmails, setFilteredEmails] = useState<EmailOption[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFocused) {
      const lastEmail = query.split(',').pop()?.trim() || '';
      if (lastEmail === '') {
        setFilteredEmails(emailOptions);
      } else {
        setFilteredEmails(
          emailOptions.filter(option => 
            option.email.toLowerCase().includes(lastEmail.toLowerCase()) || 
            option.name.toLowerCase().includes(lastEmail.toLowerCase())
          )
        );
      }
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [query, emailOptions, isFocused]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQuery(value);
    onChange(value);
    
    // Si el último carácter es una coma, abrimos las sugerencias
    if (value.endsWith(',')) {
      setShowSuggestions(true);
      setIsFocused(true);
    }
  };

  const handleSuggestionClick = (option: EmailOption) => {
    const emails = query.split(',');
    emails.pop();
    const newEmail = `${option.name} <${option.email}>`;
    const updatedQuery = [...emails, newEmail].join(', ').trim();
    setQuery(updatedQuery);
    onChange(updatedQuery);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        const length = updatedQuery.length;
        inputRef.current.setSelectionRange(length, length);
        setIsAnimating(true);
        setTimeout(() => setIsAnimating(false), 300);
      }
    }, 0);

    // Cerramos las sugerencias después de seleccionar una
    setShowSuggestions(false);
  };


  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const emails = query.split(',');
      const lastEmail = emails.pop()?.trim() || '';
      if (lastEmail) {
        const updatedQuery = [...emails, lastEmail].join(', ').trim();
        setQuery(updatedQuery);
        onChange(updatedQuery);
        setShowSuggestions(true);
        setIsFocused(true);
      }
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  };




  return (
    <div className="relative">
      <label className="block text-sm font-semibold mb-2">{label}</label>
      <input
        ref={inputRef}
        type="text"
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`p-2 border border-gray-300 rounded w-full ${isAnimating ? 'animate-pulse' : ''}`}
        placeholder="Ingrese correos electrónicos separados por comas"
      />
      {showSuggestions && filteredEmails.length > 0 && (
        <ul className="absolute top-full left-0 mt-2 w-full bg-white border border-gray-300 rounded shadow-lg z-10 max-h-60 overflow-y-auto">
          {filteredEmails.map(option => (
            <li
              key={option.email}
              onMouseDown={() => handleSuggestionClick(option)}
              className="p-2 cursor-pointer hover:bg-gray-200"
            >
              {`${option.name} <${option.email}>`}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};


interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  comment: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, comment }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded shadow-lg w-1/3">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500">
          ✖
        </button>
        <h2 className="text-xl mb-2">Comentario</h2>
        <p>{comment}</p>
      </div>
    </div>
  );
};


const Dropzone: React.FC<{
  label: string;
  files: File[];
  onFilesChange: (files: File[]) => void;
  onRemoveFile: (fileName: string, type: 'principal' | 'complementario') => void;
  type: 'principal' | 'complementario';
}> = ({ label, files, onFilesChange, onRemoveFile, type }) => {
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length > 0) {
      setIsUploading(true);
      setTimeout(() => {
        onFilesChange([...files, ...droppedFiles]);
        setIsUploading(false);
      }, 1000);
    }
  }, [files, onFilesChange]);


  

 

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleFileSelect = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    if (selectedFiles.length > 0) {
      setIsUploading(true);
      setTimeout(() => {
        onFilesChange([...files, ...selectedFiles]);
        setIsUploading(false);
      }, 1000);
    }
  };


  return (
    <div className="flex flex-col items-center">
      {files.length === 0 && (
        <div
          className={`border-2 ${isDragging ? 'border-blue-500' : 'border-dashed border-gray-300'} rounded-lg p-6 w-full max-w-4xl flex flex-col items-center justify-center ${isUploading ? 'bg-gray-100' : ''}`}
          style={{ minHeight: '100px' }} // Ajusta la altura mínima según tus necesidades
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onClick={handleFileSelect}
        >
          {isUploading ? (
            <div className="flex flex-col items-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 mb-2"></div>
              <p>Cargando...</p>
            </div>
          ) : (
            <>
              <p className="text-center text-lg font-semibold mb-2">{label}</p>
              <p className="text-center text-sm text-gray-400">Arrastra y suelta los archivos aquí o haz clic para seleccionar</p>
              <input
                ref={fileInputRef}
                type="file"
                className="hidden"
                multiple
                onChange={handleFileChange}
              />
            </>
          )}
        </div>
      )}
      {files.length > 0 && (
        <div className="mt-4 w-full max-w-4xl">
          <h4 className="font-medium mb-2 text-center">Archivos cargados:</h4>
          <div className="space-y-2">
            {files.map((file) => (
              <div key={file.name} className="flex items-center justify-between p-2 border rounded bg-gray-50">
                <span className="text-sm">{file.name}</span>
                <div className="flex items-center">
                 
               
                 
                  <button
                    onClick={() => onRemoveFile(file.name, type)}
                    className="text-red-500 hover:text-red-600 mr-2"
                  >
                    <Trash className="w-5 h-5" />
                  </button>
                  <Paperclip className="w-5 h-5" />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};





interface Option {
  value: string;
  label: string;
}


interface Document {
  id: string;
  project: string;
  projectName: string;
  projectCode: string;
  document: string;
  title: string;
  correlativo: string;
  revision: string;
  version: number;
  date: string;
  issuedBy: string;
  comment: string;
  path: string;
  flagged: boolean;
  subVersions?: Document[];
}


interface Nivel {
  nivel_id: number;
  titulo: string;
}

interface Data {
  nivel_id: number;
  concepto: string;
  nomenclatura: string;
}



interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-30 z-50">
      <div className="bg-white p-4 rounded shadow-lg">
        <h2 className="text-lg font-bold mb-4">Confirmar eliminación</h2>
        <p className="mb-4">¿Estás seguro de que deseas eliminar este documento?</p>
        <div className="flex justify-end">
          <button 
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2" 
            onClick={onConfirm}
          >
            Confirmar
          </button>
          <button 
            className="bg-gray-500 text-white px-4 py-2 rounded" 
            onClick={onClose}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};


const DocumentSearch: React.FC = () => {

  const [documents, setDocuments] = useState<Document[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>([]);
  const [clickedDocuments, setClickedDocuments] = useState<Document[]>([]);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState<Document | null>(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [isTransmittalModalOpen, setIsTransmittalModalOpen] = useState(false);

  //Sort states
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  
  const [successMessage, setSuccessMessage] = useState('');

  
  
  // Filter states
  const [projectFilter, setProjectFilter] = useState('');
  const [titleFilter, setTitleFilter] = useState('');
  const [subFolderFilter, setSubFolderFilter] = useState('');
  const [specialtyFilter, setSpecialtyFilter] = useState('');
  const [docTypeFilter, setDocTypeFilter] = useState('');
  const [commentFilter, setCommentFilter] = useState('');

  const [projectOptions, setProjectOptions] = useState<string[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [documentsPerPage, setDocumentsPerPage] = useState(5);

  const [proyectoSeleccionado, setProyectoSeleccionado] = useState<string>('');
  const [proyectos, setProyectos] = useState<Option[]>([]);
  
   // Estado para almacenar el nombre del usuario
  const [username, setUsername] = useState<string | null>(null);
  const [emisor, setEmisor] = useState<string | null>(null);
  const [tituloDocumento, setTituloDocumento] = useState('');
  const [codigo, setCodigo] = useState<string>('');
  const [correlativo, setCorrelativo] = useState<string>('');
  const [revision, setRevision] = useState<string>('');
  const [version, setVersion] = useState<number>(1);
  const [comentarios, setComentarios] = useState('');
  const [archivos, setArchivos] = useState<File[]>([]);
  const [isFilesLoaded, setIsFilesLoaded] = useState(false);
  const [archivosComplementarios, setArchivosComplementarios] = useState<File[]>([]);
  const [documentosCargados, setDocumentosCargados] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  const [niveles, setNiveles] = useState<Nivel[]>([]);
  const [data, setData] = useState<Data[]>([]);
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});
  const [modalValues, setModalValues] = useState<{ [key: string]: string }>({});;
  const [modalProject, setModalProject] = useState('');
 

  const [checkedDocuments, setCheckedDocuments] = useState<Document[]>([]);

  const [labelMessage, setLabelMessage] = useState<string>(''); // Cambiado a estado
  const [existsRevision, setExistsRevision] = useState<boolean>(false);
  const [existsDocument, setExistsDocument] = useState<boolean>(false);
  const [isSubVersion, setIsSubVersion] = useState<number>(0);
  const [newRevision, setNewRevision] = useState<string>('');
  const [newVersion, setNewVersion] = useState<number>(1);

  useEffect(() => {
    // Filtra los documentos principales que están seleccionados
    const selectedMainDocuments = filteredDocuments.filter(doc => selectedDocuments.includes(doc.id));
  
    // Filtra las subversiones de los documentos seleccionados
    const selectedSubVersions = filteredDocuments.flatMap(doc => 
      doc.subVersions ? doc.subVersions.filter(subDoc => selectedDocuments.includes(subDoc.id)) : []
    );
  
    // Combina los documentos principales y las subversiones seleccionadas
    const updatedCheckedDocuments = [...selectedMainDocuments, ...selectedSubVersions];
    
    setCheckedDocuments(updatedCheckedDocuments);
  }, [filteredDocuments, selectedDocuments]);
  


  const openDeleteModal = (doc: Document) => {
    setSelectedDocuments([doc.id]);
    setIsDeleteModalOpen(true);
  };
  
  
  useEffect(() => {
    if (archivos.length > 0) {
      setIsFilesLoaded(true);
      


    } else {
      setIsFilesLoaded(false);
    }
  }, [archivos]);
  
  
    // Función para cerrar el modal de confirmación
    const closeDeleteModal = () => {
      setIsDeleteModalOpen(false);
    };
  
    // Función para manejar la confirmación de eliminación
    const handleConfirmDelete = async () => {
      if (selectedDocuments.length > 0) {
        try {
          
          // Elimina los documentos uno por uno
          for (const docId of selectedDocuments) {
            await axios.post(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/delete_document.php`, {
              documentId: docId,
              
            });
          }
    
          // Actualiza el estado para reflejar la eliminación
          setDocuments(prev => prev.filter(doc => !selectedDocuments.includes(doc.id)));
          setSelectedDocuments([]); // Limpia la selección después de la eliminación
        } catch (error) {
          console.error('Error al eliminar documentos:', error);
          alert('Ocurrió un error al intentar eliminar los documentos.');
        } finally {
          closeDeleteModal();
        }
      }
    };
    

const handleSort = (column: string) => {
  const newDirection = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
  setSortColumn(column);
  setSortDirection(newDirection);

  const sortedDocuments = [...filteredDocuments].sort((a, b) => {
    const aValue = a[column as keyof Document];
    const bValue = b[column as keyof Document];
    
    if (aValue === undefined || bValue === undefined) {
      // Handle undefined values, e.g., by treating them as smaller or larger depending on the context.
      if (aValue === undefined && bValue === undefined) return 0;
      if (aValue === undefined) return newDirection === 'asc' ? -1 : 1;
      if (bValue === undefined) return newDirection === 'asc' ? 1 : -1;
    }
    
    if (aValue < bValue) {
      return newDirection === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return newDirection === 'asc' ? 1 : -1;
    }
    return 0;
    
  });

  setFilteredDocuments(sortedDocuments);
};
   

  useEffect(() => {
    // Fetch handleselec from the backend
    const fetchNiveles = async () => {
      try {
        const response = await axios.get<Nivel[]>(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/get_levels.php`);
        setNiveles(response.data);
      } catch (error) {
        console.error('Error fetching niveles:', error);
      }
    };

    const fetchData = async () => {
      try {
        const response = await axios.get<Data[]>(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/get_levels_data.php`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchNiveles();
    fetchData();
  }, []);

  useEffect(() => {
    // Resetea la selección general cuando cambias de página
    setIsAllSelected(false);
  }, [currentPage]);
  


  const handleDeleteDocument = async (docId: string) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/delete_document.php`, {
        documentId: docId,
      });
  
      // Actualiza el estado para reflejar la eliminación
      setDocuments(prev => prev.filter(doc => doc.id !== docId));
      setSelectedDocuments(prev => prev.filter(id => id !== docId)); // Elimina de la selección si estaba seleccionado
  
    } catch (error) {
      console.error('Error al eliminar documento:', error);
      alert('Ocurrió un error al intentar eliminar el documento.');
    } finally {
      closeDeleteModal();
    }
  };

  const handleDeleteSelectedDocuments = () => {
    if (selectedDocuments.length === 0) {
      alert('No hay documentos seleccionados para eliminar.');
      return;
    }
  
    // Abre el modal de confirmación para la eliminación múltiple
    setIsDeleteModalOpen(true);
  };
  


  
  const toggleSelectAll = () => {
    // Verifica si todos los documentos están seleccionados
    if (isAllSelected) {
      // Deselecciona todos los documentos
      setSelectedDocuments([]);
    } else {
      // Obtiene todos los documentos principales visibles en la página actual
      const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);
  
      // Extrae los IDs de los documentos principales y sus subversiones si están expandidas
      const allVisibleDocumentIds = currentDocuments.flatMap(doc => {
        // Incluye el ID del documento principal
        const docIds = [doc.id];
  
        // Verifica si el documento está expandido y sus subversiones también deben ser incluidas
        if (expandedRows.includes(doc.id) && doc.subVersions) {
          docIds.push(...doc.subVersions.map(subDoc => subDoc.id));
        }
  
        return docIds;
      });
  
      // Actualiza la lista de documentos seleccionados con todos los IDs visibles
      setSelectedDocuments(allVisibleDocumentIds);
    }
  
    // Alterna el estado de selección
    setIsAllSelected(prevState => !prevState);
  };
  
  const handleRemoveFile = (fileName: string, type: 'principal' | 'complementario') => {
    if (type === 'principal') {
      setArchivos(archivos.filter(file => file.name !== fileName));
    }
  };

  const handleSelectChange = (nivelId: number, value: string) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [nivelId]: value,
      
    }));
};




const handleBlurRevision = async () => {
 
  if (!revision) {
    return; // No hacer nada si ya se ha validado
  }
  
 // setIsRevisionFocused(true); // Marcar como validado
  try {
   
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/cargardocumento/compararCodigo.php`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ codigo: codigo, revision: revision })
      });
   
        const data = await response.json();
      
      const {
        titulo,
        existsDocument,
        existsRevision,
        revision: oldRevision,
        version: oldVersion,
        prohibited_revisions: prohibitedRevisions,
        new_revision: newRevision,
        new_version: newVersion,
        revision_allowed: revisionAllowed
    } = data;
    //alert(JSON.stringify(data));
    setExistsDocument(existsDocument);
    setTituloDocumento(existsDocument?titulo:tituloDocumento);
    setExistsRevision(existsDocument);
    setLabelMessage(existsDocument?existsRevision?`El documento ya existe y se encuentra en la revisión ${oldRevision} versión ${oldVersion}. Si continúa, el documento mantendrá su revisión en ${newRevision} y aumentará su versión a ${newVersion}`:`El documento ya existe y se encuentra en la revisión ${oldRevision} versión ${oldVersion}. Si continúa, el documento cambiará la revisión a ${newRevision} versión ${newVersion}`:`El documento se ingresará con revisión ${newRevision} versión ${newVersion}`)
    setNewRevision(newRevision);
    setNewVersion(newVersion);
    setIsSubVersion(0);
    
   
  } catch (error) {
      console.error('Error al verificar el código:', error);
      //alert('Error al verificar el código.');
  }
};


const generarCodigo = () => {
  // Aquí puedes construir tu código basado en el correlativo y otros valores si es necesario
  if (codigo && correlativo) {
    let nivelesTrama = ''; // Usa let para poder modificar el valor

    niveles.forEach(nivel => {
      // Asegúrate de que selectedValues[nivel.nivel_id] esté definido
      if (selectedValues[nivel.nivel_id]) {
        nivelesTrama += `${selectedValues[nivel.nivel_id]}-`; // Usa template literals para concatenar strings
      }
    });

    // Elimina el guion final si existe
    if (nivelesTrama.endsWith('-')) {
      nivelesTrama = nivelesTrama.slice(0, -1); // Elimina el último carácter
    }
    
    // Retorna el código como una cadena
    return `${codigo}-${nivelesTrama}-${correlativo}`;
  }
  
  // Retorna un valor predeterminado o vacío si las condiciones no se cumplen
  return '';
};



const handleModalValueChange = (nivelId: number, value: string) => {
  setModalValues(prevValues => ({
    ...prevValues,
      [nivelId]: value,
      
    }));
};


    // Function to get options based on nivel_id
    const getOptionsForNivel = (nivelId: number) => {
      return data
        .filter(item => item.nivel_id === nivelId)
        .map(item => (
          <option key={item.concepto} value={item.nomenclatura}>
            {`${item.nomenclatura} - ${item.concepto}`}
          </option>
        ));
    };
  



useEffect(() => {
  fetchDocuments();
}, []);

const fetchDocuments = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/get_docs.php`);
    const data: Document[] = await response.json();

    console.log(data);
    // Validar estructura de datos
    if (Array.isArray(data) && data.every(doc => doc.id && doc.project && doc.document && doc.date)) {
    
      setDocuments(data);
    } else {
      console.error('Estructura de datos inválida:', data);
    }
  } catch (error) {
    console.error('Error al obtener documentos:', error);
  }
};

const splitDocument = (document: string) => {
  // Divide el documento por el guion
  const parts = document.split('-');
  
  // Devuelve solo las partes relevantes
  return parts.slice(1, -1).join('-'); // Excluye la primera y la última parte
};


useEffect(() => {


  const uniqueProjects = Array.from(new Set(documents.map(doc => doc.projectName)));
  setProjectOptions(uniqueProjects);

  
   // Verifica si todos los filtros están vacíos
   const allFiltersEmpty = !projectFilter && !titleFilter && !commentFilter && 
   Object.values(selectedValues).every(value => !value);

 if (allFiltersEmpty) {
   setFilteredDocuments([]);
   return;
 }

  const filtered = documents.filter(doc => {
    const docParts = splitDocument(doc.document);
    
    const projectMatch = doc.project.toLowerCase().includes(projectFilter.toLowerCase());
    const titleMatch = doc.title.toLowerCase().includes(titleFilter.toLowerCase());
    const commentMatch = doc.comment.toLowerCase().includes(commentFilter.toLowerCase());
     // Verifica el match para las partes del documento
     const selectedValuesMatch = Object.entries(selectedValues).every(([nivelId, value]) => {
      const index = parseInt(nivelId, 10) - 1; // Ajusta el índice basado en el nivel_id
      
      // Asegúrate de que el índice esté dentro del rango de partes
      if (index < 0 || index >= docParts.split('-').length) {
        return false;
      }
      
      // Maneja la lógica específica para cada índice
      const part = docParts.split('-')[index];
      if (index === 0 && part.match(/[A-Za-z]/)) {
        return part.toLowerCase().includes(value.toLowerCase());
      } else if (index === 1 && part.length === 3) {
        return part.toLowerCase().includes(value.toLowerCase());
      } else if (index === 2 && part.length === 2) {
        return part.toLowerCase().includes(value.toLowerCase());
      }
      
      return false;
    });

    return projectMatch && titleMatch && commentMatch && selectedValuesMatch;
  });

  setFilteredDocuments(filtered);
  setCurrentPage(1); // Reset to first page on filter change
}, [projectFilter, titleFilter, subFolderFilter, specialtyFilter, docTypeFilter, commentFilter, documents, selectedValues]);

  const toggleRow = (id: string) => {
    setExpandedRows(prev =>
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
  };

  
  

  const getRowClassName = (id: string) => {
    return selectedDocuments.includes(id) ? 'bg-blue-100' : '';
  };
  
  const toggleDocumentSelection = (id: string) => {
    const updatedSelected = selectedDocuments.includes(id)
      ? selectedDocuments.filter(docId => docId !== id)
      : [...selectedDocuments, id];
  
    setSelectedDocuments(updatedSelected);
    setIsAllSelected(updatedSelected.length === filteredDocuments.length);
  };


// Función para separar el documento_id
  function separarDocumentoId(documentoId: string): { codigo: string, niveles: string[], correlativo: string } {
    
    // Dividir el documento_id en partes usando el delimitador '-'
      const partes = documentoId.split('-');

     // alert(partes);
      // Asegurarse de que el documento_id tiene al menos tres partes
      if (partes.length < 3) {
          //throw new Error('El documento_id no tiene el formato esperado.');
      }
      
      // Extraer las partes del documento_id
      const codigo = partes[0];
      const correlativo = partes[partes.length - 1];
      const niveles = partes.slice(1, -1);
      // Devolver las partes separadas
      return { codigo, niveles, correlativo };
  }

  const openEditModal = (doc: Document) => {
    
    setCurrentDocument(doc);
   
 // Separar el documento_id en partes
    const tramaDocArray = separarDocumentoId(doc.document);
   
    //setProyectoSeleccionado(doc.project);
    
     // Separar el documento_id en partes (cadena 'A,EDI,IN')
  const nivelDocArray = tramaDocArray.niveles;
 
     // Establecer los valores en los comboboxes
  const newSelectedValues: { [key: string]: string } = {
    1: nivelDocArray[0] || '',
    2: nivelDocArray[1] || '',
    3: nivelDocArray[2] || '',
  };
  
  setModalValues(newSelectedValues);

    setModalProject(doc.projectName);
    setTituloDocumento(doc.title);
    setCodigo(doc.document);
    setUsername(doc.issuedBy);
    setCorrelativo(tramaDocArray.correlativo);
    setRevision(doc.revision);
    setVersion(doc.version);
   // alert(`${revision}${version}`)
    setComentarios(doc.comment);
    // Agrega cualquier otro estado necesario aquí
    setIsEditModalOpen(true);
};

  

  const getCurrentDateTimeString = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
};


// Busca un documento por ID en el documento principal o en las subversiones
const getDocumentById = (id: string): string | null => {
  // Busca en los documentos principales
  const doc = documents.find(doc => doc.id === id);
  
  // Si el documento se encuentra en los documentos principales, retorna su path
  if (doc) {
    return doc.path;
  }

  // Si el documento no se encuentra en los documentos principales, busca en las subversiones
  for (const doc of documents) {
    const subdoc = doc.subVersions?.find(subdoc => subdoc.id === id);
    if (subdoc) {
      return subdoc.path;
    }
  }

  // Si el documento o subdocumento no se encuentra, retorna null
  console.error('Documento no encontrado:', id);
  return null;
};

// Estado de los documentos, enviado una vez
const docStates = {
  "preliminary": 1,
  "information": 0,
  "approval": 1,
  "construction": 0
};




const handleTransmittal = () => {
  // Aquí irá la lógica para generar y enviar el transmittal
  setIsTransmittalModalOpen(true);


};



const handleCloseTransmittalModal = () => {
  setIsTransmittalModalOpen(false);
  setSelectedDocuments([]);
  setIsAllSelected(false);

};

const handleSubmitTransmittal = () => {
  // Aquí irá la lógica para enviar el transmittal
  setIsTransmittalModalOpen(false);
};

  const handleDownload = async (documentIds: string[]) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/php/pages/controldocument/busquedarapida/download_documents.php?type=type1`,
            { documentIds },
            { responseType: 'blob' }
        );
        



        const contentDisposition = response.headers['content-disposition'];
        
        
        let filename = 'download.zip'; // Default filename




        if (documentIds.length === 1) {
           // filename = 'download'; // Fallback if filename is not provided
          
            documentIds.forEach(id => {
             
                const documentValue = getDocumentById(id);
               
                if (documentValue) {
                   // alert(`Documento ID ${id}: ${documentValue}`);
                   filename = documentValue;
                   
                } else {
                    console.log(`No se encontró el documento con ID: ${id}`);
                }
            });
        }else{
           filename = `Simagi_Docs_${getCurrentDateTimeString()}.zip`;
        }

        // Guarda el archivo con el nombre recibido desde el backend
        saveAs(response.data, filename);
    } catch (error) {
        console.error('Error downloading documents:', error);
        if (axios.isAxiosError(error)) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                alert(`Error: ${error.response.data.message || 'Unknown error occurred'}`);
            } else if (error.request) {
                console.error('No response received:', error.request);
                alert('No se recibió respuesta del servidor. Por favor, intente de nuevo más tarde.');
            } else {
                console.error('Error', error.message);
                alert('Ocurrió un error al procesar la solicitud. Por favor, intente de nuevo.');
            }
        } else {
            console.error('Non-Axios error:', error);
            alert('Ocurrió un error inesperado. Por favor, intente de nuevo.');
        }
    }
};

  
  
  const handleEdit = (updatedDoc: Document) => {
    setDocuments(prev =>
      prev.map(doc =>
        doc.id === updatedDoc.id
          ? { ...doc, ...updatedDoc } // Actualiza el documento con los cambios
          : doc
      )
    );
    setIsEditModalOpen(false);
  }

  const handleDelete = () => {
    if (currentDocument) {
      setDocuments(prev => prev.map(doc => 
        doc.id === currentDocument.id
          ? { ...doc, subVersions: doc.subVersions?.filter(subDoc => subDoc.id !== currentDocument.id) }
          : doc
      ).filter(doc => doc.id !== currentDocument.id));
    }
    setIsDeleteModalOpen(false);
  };



  const TextArea: React.FC<{
    label: string;
    value: string;
    onChange: (value: string) => void;
  }> = ({ label, value, onChange }) => (
    <div className="mb-2">
      <label className="block text-sm font-medium mb-1">{label}</label>
      <textarea
        className="border rounded px-2 py-1 w-full h-16 resize-none text-sm"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );


const Select: React.FC<{
  label: string;
  value: string;
  onChange: (value: string) => void;
  options: { value: string; label: string }[];
}> = ({ label, value, onChange, options }) => (
  <div className="mb-2">
    <label className="block text-sm font-medium mb-1">{label}</label>
    <select
      className="border rounded px-2 py-1 w-full text-sm"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="">Seleccione una opción</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);






  const downloadSelectedDocuments = () => {
    if (selectedDocuments.length > 0) {
      handleDownload(selectedDocuments);
      
    } else {
      alert('Por favor, seleccione al menos un documento para descargar.');
    }
  };


  const transmittalSelectedDocuments = () => {
    if (selectedDocuments.length > 0) {
   //  alert(selectedDocuments)
      handleTransmittal();
      
    } else {
      alert('Por favor, seleccione al menos un documento para generar el transmittal.');
    }
  };

  // Pagination logic
  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);

  const totalPages = Math.ceil(filteredDocuments.length / documentsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //transmital


  const handleProjectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProyectoSeleccionado(e.target.value);
  };

  const handleRecordsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDocumentsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page
  };


  const viewFile = (path: string) => {
    
    window.open(`${process.env.REACT_APP_API_URL}/uploads/${path}`, '_blank'); // Abre el archivo en una nueva pestaña
  };
/*
  useEffect(() => {
    // Sincroniza projectFilter con proyectoSeleccionado cuando se monta el componente
    if (proyectoSeleccionado) {
      setProjectFilter(proyectoSeleccionado);
    } else {
      setProjectFilter('');
    }
  }, [proyectoSeleccionado]);
*/
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Búsqueda de documentos</h1>
      
      <div className="mb-4 overflow-x-auto">
        <button 
          
          onClick={downloadSelectedDocuments}
          disabled={selectedDocuments.length === 0}
          className={`px-4 py-2 text-white rounded-md ${
            selectedDocuments.length > 0
              ? 'bg-teal-600 hover:bg-teal-700 cursor-pointer'
              : 'bg-gray-400 cursor-not-allowed'
          }`}
        >
          <Download className="inline-block mr-2" />
          Descargar Documentos Seleccionados
        </button>
       
        <button 
          
          onClick={transmittalSelectedDocuments}
          disabled={selectedDocuments.length === 0}
          className={`ml-3 px-4 py-2 text-white rounded-md ${
            selectedDocuments.length > 0
              ? 'bg-yellow-600 hover:bg-yellow-700 cursor-pointer'
              : 'bg-gray-400 cursor-not-allowed'
          }`}
        >
          <Mail className="inline-block mr-2" />
          Generar Transmittal
        </button>

        <TransmittalModal
        
        isOpen={isTransmittalModalOpen}
        onClose={handleCloseTransmittalModal}
        onSubmit={handleSubmitTransmittal}
        documents={checkedDocuments}
      />
      </div>
      
      <div className="grid grid-cols-3 gap-4 mb-4 ">
      <select
      id="projectFilter"
      className="p-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      value={projectFilter}
      onChange={(e) => setProjectFilter(e.target.value)}
      aria-label="Filtro de Procesos"
      
    >
      <option value="">Seleccionar proceso</option>
      {projectOptions.length > 0 ? (
        projectOptions.map((project) => (
          <option key={project} value={project}>
            {project}
          </option>
        ))
      ) : (
        <option disabled>No hay procesos disponibles</option>
      )}
    </select>
    <input 
          className="border p-2 rounded" 
          placeholder="Filtro por Título" 
          value={titleFilter}
          onChange={(e) => setTitleFilter(e.target.value)}
        />
        <input 
          className="border p-2 rounded" 
          placeholder="Comentario" 
          value={commentFilter}
          onChange={(e) => setCommentFilter(e.target.value)}
        />
       
        {niveles.map(nivel => (
          <div key={nivel.nivel_id}>
            <label htmlFor={`select-${nivel.nivel_id}`} className="block text-lg font-semibold mb-2 ">
              {nivel.titulo}
            </label>
            <select
              id={`select-${nivel.nivel_id}`}
              
              value={selectedValues[nivel.nivel_id] || ''}
              onChange={(e) => handleSelectChange(nivel.nivel_id, e.target.value)}
             
              className="p-2 border border-gray-300 rounded w-full"
            >
              <option value="">Seleccione {nivel.titulo}</option>
              {getOptionsForNivel(nivel.nivel_id)}
            </select>
          </div>
        ))}
      
      </div>
      {filteredDocuments.length > 0 ?  (
    <>
      
      <div className="mb-4 flex items-center ">
        <span className="mr-2">Mostrar</span>
        <select 
          className="border p-2 rounded mr-4"
          value={documentsPerPage}
          onChange={handleRecordsPerPageChange}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={250}>250</option>
          <option value={500}>500</option>
        </select>
        <span>registros por página</span>
        {


/*


{selectedDocuments.length > 0 && (
        <div className="ml-auto">
          <button 
            onClick={handleDeleteSelectedDocuments} 
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Eliminar Documentos Seleccionados
          </button>
        </div>
      )}

*/

        }
      </div>
      
     
      <table className="min-w-full border shadow-md w-full">
        <thead className="sticky top-0 bg-cyan-500 text-white"> 
          <tr className="bg-cyan-500 text-white">
            <th className="p-2">      <input
        type="checkbox"
        checked={isAllSelected}
        onChange={toggleSelectAll}
      /></th>
            <th className="p-2"></th>
            <th className="p-2"></th>
   <th className="border-b p-2" onClick={() => handleSort('project')} style={{ cursor: 'pointer' }}>
    Proceso {sortColumn === 'project' && (sortDirection === 'asc' ? '▲' : '▼')}
</th>
<th className="border-b p-2" onClick={() => handleSort('document')} style={{ cursor: 'pointer' }}>
    Documento {sortColumn === 'document' && (sortDirection === 'asc' ? '▲' : '▼')}
</th>
<th className="border-b p-2" onClick={() => handleSort('title')} style={{ cursor: 'pointer' }}>
    Título {sortColumn === 'title' && (sortDirection === 'asc' ? '▲' : '▼')}
</th>
<th className="border-b p-2" onClick={() => handleSort('revision')} style={{ cursor: 'pointer' }}>
    Rev {sortColumn === 'revision' && (sortDirection === 'asc' ? '▲' : '▼')}
</th>
<th className="border-b p-2">
    V {/* No tiene función de ordenamiento */}
</th>
<th className="border-b p-2" onClick={() => handleSort('date')} style={{ cursor: 'pointer' }}>
    Fecha{sortColumn === 'date' && (sortDirection === 'asc' ? '▲' : '▼')}
</th>
<th className="border-b p-1" onClick={() => handleSort('issuedBy')} style={{ cursor: 'pointer' }}>
    Emisor {sortColumn === 'issuedBy' && (sortDirection === 'asc' ? '▲' : '▼')}
</th>
<th className="border-b" onClick={() => handleSort('comment')} style={{ cursor: 'pointer' }}>
    Comentario {sortColumn === 'comment' && (sortDirection === 'asc' ? '▲' : '▼')}
</th>

            <th className="border-b p-2">Acciones</th>
          </tr>
        </thead>
        <tbody>
          
          {currentDocuments.map(doc => (
            <React.Fragment key={doc.id}>
               <tr key={doc.id} className={getRowClassName(doc.id)}>
                <td className="border-b p-2">
                  <input 
                  
                    type="checkbox" 
                    checked={selectedDocuments.includes(doc.id)}
                     onChange={() => toggleDocumentSelection(doc.id)}
                  />
                </td>
                <td className="border-b p-2">
                  {doc.subVersions && doc.subVersions.length > 0 && (
                    <button 
                      onClick={() => toggleRow(doc.id)}
                      className="w-6 h-6 flex items-center justify-center rounded"
                    >
                      {expandedRows.includes(doc.id) ? <MinusCircle /> : <PlusCircle />}
                    </button>
                  )}
                </td>
                <td className="border-b p-2">
                  {doc.flagged && <Flag className="text-red-500" />}
                </td>
                <td className="border-b p-2">{doc.projectName}</td>
                <td className="border-b p-2">{doc.document}</td>
                <td className="border-b p-2">{doc.title}</td>
                <td className="border-b p-2">{doc.revision}</td>
                <td className="border-b p-2">{doc.version}</td>
                <td className="border-b p-2">{doc.date}</td>
                <td className="border-b p-2">{doc.issuedBy}</td>
                <td className="border-b p-2">{doc.comment}</td>
                <td className="border-b p-2">
                  <div className="flex ">
                  <button className='text-blue-500' onClick={() => viewFile(doc.path)}><Eye size={20}/> </button>
                    <button className="text-yellow-500" onClick={() => openEditModal(doc)}><Edit size={20} /></button>
                  
                    <button disabled onClick={() => openDeleteModal(doc)} className="text-gray-600 hover:text-gray-800">
        
      
        <Trash size={20} />
      </button>
          
      {/* Modal de confirmación */}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleConfirmDelete}
      />
                  </div>
                </td>
              </tr>
              {doc.subVersions && expandedRows.includes(doc.id) && doc.subVersions.map(subDoc => (
                <tr key={subDoc.id} className="bg-gray-100">
                  <td className="border-b p-2">
                    <input 
                      type="checkbox" 
                      checked={selectedDocuments.includes(subDoc.id)}
                      onChange={() => toggleDocumentSelection(subDoc.id)}
                    />
                  </td>
                  <td className="border-b p-2"></td>
                  <td className="border-b p-2">
                    {subDoc.flagged && <Flag className="text-red-500" />}
                  </td>
                  <td className="border-b p-2">{subDoc.project}</td>
                  <td className="border-b p-2">{subDoc.document}</td>
                  <td className="border-b p-2">{subDoc.title}</td>
                  <td className="border-b p-2">{subDoc.revision}</td>
                  <td className="border-b p-2">{subDoc.version}</td>
                  <td className="border-b p-2">{subDoc.date}</td>
                  <td className="border-b p-2">{subDoc.issuedBy}</td>
                  <td className="border-b p-2">{subDoc.comment}</td>
                  <td className="border-b p-2">
                    <div className="flex space-x-2">
                    <button className='text-yellow-500' onClick={() => viewFile(subDoc.path)}><File size={20}/> </button>
                     { /*<button className="text-blue-500" onClick={() => openEditModal(subDoc)}><Edit size={20} /></button>*/}
                      <button disabled className="text-gray-500" onClick={() => openDeleteModal(subDoc)}><Trash size={20} /></button>
                    
                    </div>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <span>
          Página {currentPage} de {totalPages}
        </span>
        <div>
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(pageNumber => (
            <button 
              key={pageNumber}
              className={`px-3 py-1 mx-1 rounded ${currentPage === pageNumber ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
              onClick={() => handlePageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          ))}
        </div>
      </div>
      </>
      ): (
        <p></p>
      )}
   {/* Edit Modal */}
{isEditModalOpen && currentDocument && (
  
  <Dialog isOpen={isEditModalOpen} onClose={() => { 
    //setIsEditModalOpen(false); 
    setLabelMessage(''); 
}}>
    <DialogContent>
    <label className=" text-md font-medium mb-1">Editar Documento</label>
      {/* Edit Form Content */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleEdit(currentDocument);
        }}
      >
        <div className="bg-white rounded-lg p-4">
        <label className="block text-sm font-medium mb-1">Proceso</label>
          <div className="grid grid-cols-2 gap-2">
      
          <input
                className="border rounded px-2 py-1 w-full text-sm cursor-not-allowed"
                type="text"
                value={modalProject || ''}
                disabled
              />
    
        <br></br>
            
            <div>
            <label className="block text-sm font-medium mb-1">Documento</label>
              <input
                className="border rounded px-2 py-1 w-full text-sm cursor-not-allowed"
                type="text"
                value={codigo}
                disabled
              />
            
              <label className="block mt-3 text-sm font-medium mb-1">Emitido por*</label>
              <input
                className="border rounded px-2 py-1 w-full text-sm cursor-not-allowed"
                type="text"
                value={username || ''}
                disabled
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Título del documento*</label>
              <input
                className="border rounded px-2 py-1 w-full uppercase text-sm"
                
                type="text"
                value={tituloDocumento}
                onChange={(e) => setTituloDocumento(e.target.value)}
              />
            </div>
         
            {niveles.map((nivel) => (
              <div key={nivel.nivel_id}>
                <label htmlFor={`select-${nivel.nivel_id}`} className="block text-sm font-medium mb-1">
                  {nivel.titulo}
                </label>
                <select
                  disabled
                  id={`select-${nivel.nivel_id}`}
                  value={modalValues[nivel.nivel_id] || ''}
                  onChange={(e) => handleSelectChange(nivel.nivel_id, e.target.value)}
                  className="cursor-not-allowed border rounded px-2 py-1 w-full text-sm"
                >
                  <option value="">Seleccione {nivel.titulo}</option>
                  {getOptionsForNivel(nivel.nivel_id)}
                </select>
              </div>
            ))}
            <div>
              <label className="block text-sm font-medium mb-1">Correlativo</label>
              <input
                className="cursor-not-allowed border rounded px-2 py-1 w-full text-sm"
                type="number"
                value={correlativo}
                disabled
                onChange={(e) => setCorrelativo(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Revisión</label>
              <input
  className="border rounded px-2 py-1 w-full text-sm"
  type="text"
  value={revision}
  disabled={!isFilesLoaded}
  
  onBlur={handleBlurRevision}
  onChange={(e) => {
    const input = e.target.value.toUpperCase();

    // Verificar si el input contiene letras y números
    const hasLetters = /[A-Z]/.test(input);
    const hasNumbers = /\d/.test(input);
   
    let newValue = input;

    // Si contiene letras, permitir solo letras y limitar a un carácter
    if (hasLetters) {
      newValue = input.replace(/[^A-Z]/g, "").slice(0, 1);
    }

    // Si contiene números, permitir solo números y sin límite
    if (hasNumbers) {
      newValue = input.replace(/[^0-9]/g, "");
    }
    
    setRevision(newValue);
    
  }
  
  }
/>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Versión</label>
              <input
                className="border rounded px-2 py-1 w-full text-sm cursor-not-allowed"
                type="number"
                value={version}
                onChange={(e) => setRevision(e.target.value)}
                disabled
              />
            </div>
           
          </div>
          {labelMessage && (
          <div 
            className={`mt-5 p-4 inline-block rounded mb-4 text-white ${
              existsRevision ?   'bg-yellow-200 text-yellow-800' : 'bg-green-200 text-custom-sidebar'
            }`}
          >
            {labelMessage}
          </div>
        )}
          <div className="col-span-2 w-full">
          <TextArea
        label="Comentarios"
        value={comentarios.toUpperCase()}
        onChange={setComentarios}
      />
          </div>
          <div className="col-span-2  w-full justify-center">
          <Dropzone
  label="Agregar documento"
  files={archivos}
  onFilesChange={setArchivos}
  onRemoveFile={handleRemoveFile}
  type="principal"

   
/> 

          <Button className="bg-teal-500 hover:bg-teal-900 text-white px-4 mt-3 py-2 rounded mr-2" type="submit">Guardar</Button>
          <Button variant="outline" onClick={() => {setIsEditModalOpen(false); 
    setLabelMessage('')}}>Cancelar</Button>
          </div>
        </div>
      </form>
    </DialogContent>
  </Dialog>
)}

    </div>
  );
};

export default DocumentSearch;