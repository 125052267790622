import React, { useState } from 'react';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { Maximize2, Minimize2 } from 'lucide-react';

const TramitacionDashboard = () => {
  const [isZoomed, setIsZoomed] = useState({ pie: false, bar: false });
  const [showWeeks, setShowWeeks] = useState(false);

  const convertToWeeks = (days: number) => (days / 7).toFixed(1);

  const data = [
    {
      subproceso: 'Subproceso 1',
      permiso: 'Permiso 3',
      ingreso: 1,
      admisibilidad: 11,
      observacion: 22,
      respuestaTitular: 16,
      resolucion: 20,
      plazoTotal: 70,
      estado: 'No admitido'
    },
    {
      subproceso: 'Subproceso 1',
      permiso: 'Permiso 1',
      ingreso: 1,
      admisibilidad: 12,
      observacion: 22,
      respuestaTitular: 20,
      resolucion: 8,
      plazoTotal: 63,
      estado: 'Desistido'
    },
    {
      subproceso: 'Subproceso 1',
      permiso: 'Permiso 5',
      ingreso: 1,
      admisibilidad: 14,
      observacion: 18,
      respuestaTitular: 11,
      resolucion: 9,
      plazoTotal: 53,
      estado: 'Rechazado'
    },
    {
      subproceso: 'Subproceso 1',
      permiso: 'Permiso 6',
      ingreso: 1,
      admisibilidad: 16,
      observacion: 14,
      respuestaTitular: 10,
      resolucion: 11,
      plazoTotal: 52,
      estado: 'Aprobado'
    },
    {
      subproceso: 'Subproceso 1',
      permiso: 'Permiso 2',
      ingreso: 1,
      admisibilidad: 10,
      observacion: 8,
      respuestaTitular: 12,
      resolucion: 19,
      plazoTotal: 50,
      estado: 'Aprobado'
    },
    {
      subproceso: 'Subproceso 1',
      permiso: 'Permiso 4',
      ingreso: 1,
      admisibilidad: 10,
      observacion: 12,
      respuestaTitular: 7,
      resolucion: 15,
      plazoTotal: 45,
      estado: 'Aprobado'
    }
  ];

  const pieData = Object.entries(
    data.reduce((acc: Record<string, number>, curr) => {
      acc[curr.estado] = (acc[curr.estado] || 0) + 1;
      return acc;
    }, {})
  ).map(([name, value]) => ({ name, value }));
  
  

  const barData = data
    .sort((a, b) => a.plazoTotal - b.plazoTotal)
    .map(item => ({
    permiso: item.permiso,
    'Ingreso (Días)': showWeeks ? convertToWeeks(item.ingreso) : item.ingreso,
    'Admisibilidad (Días)': showWeeks ? convertToWeeks(item.admisibilidad) : item.admisibilidad,
    'Observaciones (Días)': showWeeks ? convertToWeeks(item.observacion) : item.observacion,
    'Respuesta Titular (Días)': showWeeks ? convertToWeeks(item.respuestaTitular) : item.respuestaTitular,
    'Resolución (Días)': showWeeks ? convertToWeeks(item.resolucion) : item.resolucion
  }));

  const COLORS: Record<string, string> = {
    'Aprobado': '#4CAF50',
    'No admitido': '#FFC107',
    'Desistido': '#2196F3',
    'Rechazado': '#F44336'
  };

  const BAR_COLORS = {
    'Ingreso (Días)': '#2196F3',
    'Admisibilidad (Días)': '#FF9800',
    'Observaciones (Días)': '#757575',
    'Respuesta Titular (Días)': '#FFC107',
    'Resolución (Días)': '#1976D2'
  };

  return (
    <div className="p-4 bg-white">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-medium">Resumen de Tramitación</h1>
        <button
          onClick={() => setShowWeeks(!showWeeks)}
          className="px-3 py-1 text-sm bg-blue-600 text-white rounded"
        >
          Mostrar en {showWeeks ? 'Días' : 'Semanas'}
        </button>
      </div>
      
      {/* Tabla */}
      <div className="mb-4 overflow-hidden border border-gray-200">
        <table className="w-full text-sm">
          <thead className="bg-blue-600 text-white">
            <tr>
              <th className="px-3 py-2 text-left">Subproceso</th>
              <th className="px-3 py-2 text-left">Permiso</th>
              <th className="px-3 py-2 text-left">Ingreso ({showWeeks ? 'Semanas' : 'Días'})</th>
              <th className="px-3 py-2 text-left">Admisibilidad ({showWeeks ? 'Semanas' : 'Días'})</th>
              <th className="px-3 py-2 text-left">Observación ({showWeeks ? 'Semanas' : 'Días'})</th>
              <th className="px-3 py-2 text-left">Respuesta Titular ({showWeeks ? 'Semanas' : 'Días'})</th>
              <th className="px-3 py-2 text-left">Resolución ({showWeeks ? 'Semanas' : 'Días'})</th>
              <th className="px-3 py-2 text-left">Plazo total</th>
              <th className="px-3 py-2 text-left">Estado</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {data.map((row, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-3 py-2">{row.subproceso}</td>
                <td className="px-3 py-2">{row.permiso}</td>
                <td className="px-3 py-2">{showWeeks ? convertToWeeks(row.ingreso) : row.ingreso}</td>
                <td className="px-3 py-2">{showWeeks ? convertToWeeks(row.admisibilidad) : row.admisibilidad}</td>
                <td className="px-3 py-2">{showWeeks ? convertToWeeks(row.observacion) : row.observacion}</td>
                <td className="px-3 py-2">{showWeeks ? convertToWeeks(row.respuestaTitular) : row.respuestaTitular}</td>
                <td className="px-3 py-2">{showWeeks ? convertToWeeks(row.resolucion) : row.resolucion}</td>
                <td className="px-3 py-2">{showWeeks ? convertToWeeks(row.plazoTotal) : row.plazoTotal}</td>
                <td className="px-3 py-2">
                  <span className={`px-2 py-1 rounded text-xs
                    ${row.estado === 'Aprobado' ? 'bg-green-500 text-white' : ''}
                    ${row.estado === 'Rechazado' ? 'bg-red-500 text-white' : ''}
                    ${row.estado === 'No admitido' ? 'bg-yellow-500 text-white' : ''}
                    ${row.estado === 'Desistido' ? 'bg-blue-500 text-white' : ''}`}
                  >
                    {row.estado}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Gráficos */}
      <div className="flex gap-4">
        {/* Gráfico de torta */}
        <div className={`relative border border-gray-200 p-4 ${isZoomed.pie ? 'fixed inset-4 z-50 bg-white' : 'w-1/2'}`}>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-sm font-medium">Distribución de Estados - Subproceso 1</h3>
            <button onClick={() => setIsZoomed({ ...isZoomed, pie: !isZoomed.pie })}>
              {isZoomed.pie ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
            </button>
          </div>
          <ResponsiveContainer width="100%" height={280}>
            <PieChart>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({percent}) => `${(percent * 100).toFixed(0)}%`}
                outerRadius={100}
                dataKey="value"
              >
                {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[entry.name] || '#000000'} />
                ))}
              </Pie>
              <Legend />
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Gráfico de barras */}
        <div className={`relative border border-gray-200 p-4 ${isZoomed.bar ? 'fixed inset-4 z-50 bg-white' : 'w-1/2'}`}>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-sm font-medium">Resumen de Tramitación por Permiso</h3>
            <button onClick={() => setIsZoomed({ ...isZoomed, bar: !isZoomed.bar })}>
              {isZoomed.bar ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
            </button>
          </div>
          <ResponsiveContainer width="100%" height={280}>
            <BarChart
              data={barData}
              layout="vertical"
              margin={{ top: 5, right: 30, left: 50, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" domain={[0, showWeeks ? 12 : 80]} />
              <YAxis dataKey="permiso" type="category" />
              <Tooltip />
              <Legend />
              {Object.entries(BAR_COLORS).map(([key, color]) => (
                <Bar key={key} dataKey={key} stackId="a" fill={color} />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default TramitacionDashboard;