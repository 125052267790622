import React, { useState, useEffect, useRef, useCallback, createContext, useContext } from 'react';
import { ChevronDown, ChevronLeft, ChevronRight, Search, Bell, Eye, User, Home, Menu, X, Minimize2, Settings, HelpCircle, Info, LogOut, Calendar, FileText, BarChart2, Briefcase, Book, Send, DollarSign, Users, AreaChart, XCircle } from 'lucide-react';
import logoImage from '../img/logo3.png';
import bgImage from '../img/background2.png';
import { useNavigate } from 'react-router-dom';

//Planificacion
import planificacionMisTareas from '../components/planificacion/misTareas';
import planificacionVistaGeneral from '../components/planificacion/vistaGeneral';
import planificacionGanttGeneral from '../components/planificacion/ganttGeneral';
import planificacionPermisologia from '../components/planificacion/permisologia';
import planificacionResumenPermisologia from '../components/planificacion/resumenPermisologia';
import planificacionPanel from '../components/planificacion/panel';
//Superbandeja
import matrizEisenhower from '../components/superbandeja/matrizEisenhower';
import todasLasTareas from '../components/superbandeja/todasLasTareas';
//Analiticas
import huellaDigital from '../components/analiticas/huellaDigital';
import riskMatrix from '../components/analiticas/riskMatrix';
import reporteDeProgreso from '../components/analiticas/reporteDeProgreso';
import reporteDeUsuarioYdepartamento from '../components/analiticas/reporteDeUsuarioYdepartamento';
import rentabilidad from '../components/analiticas/rentabilidad';
//Gestión
import curvaS from '../components/gestion/curvas';
import curvaDeVelocidad from '../components/gestion/curvaDeVelocidad';
import eepp from '../components/gestion/eepp';
import adicionales from '../components/gestion/adicionales';
import reporteSemanal from '../components/gestion/reporteSemanal';
import requerimientosDeInformacion from '../components/gestion/requerimientosDeInformacion';

//Control de documentos
import glosario from '../components/controlDocumentos/glosario';
import reporte from '../components/controlDocumentos/reporte';
import busquedaRapida from '../components/controlDocumentos/busquedaRapida';

import cargaDocumentos from '../components/controlDocumentos/cargaDocumentos';
//Transmital 
import documentosObra from '../components/transmittal/documentosObra';
import transmittal from '../components/transmittal/transmittal';
//Evaluación Técnico Comercial
import ETC from '../components/metodologiaETC/etc';
import EPIResumen from '../components/metodologiaETC/EPIResumen';
import calculoTIR from '../components/metodologiaETC/calculoTIR';
import resumenEvaluacionTC from '../components/metodologiaETC/resumenEvaluacionTC';
//Administracion
import crear from '../components/administracion/crear';
import InviteUser from '../components/administracion/InviteUser';
import editUser from '../components/administracion/editUser';
import editPlantilla from '../components/administracion/editPlantilla';
import editPlanif from '../components/administracion/editPlanif';
import editarProcesos from '../components/administracion/editProcesos'

//Inicio
import inicio from '../components/inicio'
import estadisticas from '../components/estadisticas'

import useOutsideClick from '../hooks/useOutsideClick'; // Importa el hook personalizado
import { CommonComponentProps, ComponentWithOptionalProps } from '../types/common';


// Actualiza la definición de ComponentMap
interface ComponentMap {
  [key: string]: {
    [key: string]: React.ComponentType<Partial<CommonComponentProps>>;
  };
}


const componentMap: ComponentMap = {
  'Planificación': {
    'Mis tareas': planificacionMisTareas as ComponentWithOptionalProps,
    'Carta Gantt': planificacionGanttGeneral as ComponentWithOptionalProps,
    'Vista General': planificacionVistaGeneral as ComponentWithOptionalProps,
    'Permisología': planificacionPermisologia as ComponentWithOptionalProps,
    'Tablero': planificacionPanel as ComponentWithOptionalProps,
    'Resumen Permisología': planificacionResumenPermisologia as ComponentWithOptionalProps,

  },
  'SUPERBANDEJA': {
    'Matriz de Eisenhower': matrizEisenhower,
    'Todas las tareas': todasLasTareas,
  },
  'ANALITICAS':{
    'Huella digital': huellaDigital as ComponentWithOptionalProps,
    'Reporte de progreso': reporteDeProgreso,
    'Reporte de usuario y Departamento': reporteDeUsuarioYdepartamento,
    'Rentabilidad': rentabilidad,
    'Matriz de Riesgo': riskMatrix,
  },
  'Gestión':{
    'Curva S':curvaS,
    'Curva de Velocidad':curvaDeVelocidad,
    'EEPP': eepp,
    'Adicionales':adicionales,
    'Reporte semanal':reporteSemanal,
    'Requerimientos de información':requerimientosDeInformacion,
  },'Control de Documentos':{
    'Glosario':glosario,
    'Reporte':reporte,
    'Búsqueda rápida':busquedaRapida,
    'Carga de documentos':cargaDocumentos,
  },'TRANSMITTAL':{
    'Documentos emitidos a Obra':documentosObra,
    'Registro de Transmittal':transmittal,
  },'EVALUACIÓN TÉCNICO COMERCIAL':{
    'Metodología ETC':ETC,
    'EPI Resumen': EPIResumen,
    'Cálculo de TIR':calculoTIR,
    'Resumen Evaluación Técnico Comercial':resumenEvaluacionTC,
  },'ADMINISTRACIÓN':{
    'Crear proceso':crear,
    'Editar proceso':editarProcesos,
    'Crear usuarios':InviteUser,
    'Editar usuarios':editUser,
    'Mis plantillas': editPlantilla,
    'Mis planificaciones': editPlanif,
  },

  'Inicio':{
    '': inicio,
    'Volver al Inicio': inicio,
    'Estadisticas': estadisticas,
  },
 
};



interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[]; // Añadimos esta propiedad
  plantilla: string; // Nombre de la plantilla asociada al proceso

}



// Crear un contexto para la navegación
const NavigationContext = createContext({
  handleNavigation: (direction: 'prev' | 'next') => {},
  getCurrentItems: () => [] as string[],
  activeSection: { main: '', sub: '' },
});

// Componente para las flechas de navegación
const NavigationArrows = () => {
  const [showArrows, setShowArrows] = useState(false);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isMouseNearEdgeRef = useRef(false);
  const { handleNavigation } = useContext(NavigationContext);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    const threshold = 100;
    const isNearEdge = e.clientX <= threshold || e.clientX >= window.innerWidth - threshold;
    
    isMouseNearEdgeRef.current = isNearEdge;

    if (isNearEdge) {
      setShowArrows(true);
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
      hideTimeoutRef.current = setTimeout(() => {
        if (!isMouseNearEdgeRef.current) {
          setShowArrows(false);
        }
      }, 2000);
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    isMouseNearEdgeRef.current = false;
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
    }
    setShowArrows(false);
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseleave', handleMouseLeave);
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
    };
  }, [handleMouseMove, handleMouseLeave]);
  return (
    <>
      <div className={`fixed top-1/2 left-0 z-[200] flex items-center transition-opacity duration-300 ${showArrows ? 'opacity-100' : 'opacity-0'} transform -translate-y-1/2`}>
        <button
          onClick={() => handleNavigation('prev')}
          className="shadow-md p-5 bg-gray-200 rounded-r-full hover:bg-custom-sidebar hover:text-white transition-colors duration-200"
          aria-label="Previous"
        >
          <ChevronLeft className="h-12 w-12" />
        </button>
      </div>
      <div className={`fixed top-1/2 right-0 z-[200] flex items-center transition-opacity duration-300 ${showArrows ? 'opacity-100' : 'opacity-0'} transform -translate-y-1/2`}>
        <button
          onClick={() => handleNavigation('next')}
          className="shadow-md p-5 bg-gray-200 rounded-l-full hover:bg-custom-sidebar hover:text-white transition-colors duration-200"
          aria-label="Next"
        >
          <ChevronRight className="h-12 w-12" />
        </button>
      </div>
    </>
  );
};  


const SimagiInteractivePreview = () => {

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isChatMinimized, setIsChatMinimized] = useState(false);


  const [selectedProcess, setSelectedProcess] = useState<string>('');
  const [processes, setProcesses] = useState<Process[]>([]);

  const navigate = useNavigate();

  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const [openSidebar, setOpenSidebar] = useState<string | null>(null);
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});
  const [openMobile, setOpenMobile] = useState<string | null>(null);
  const [activeSection, setActiveSection] = useState({ main: 'Inicio', sub: '' });
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isProcessOpen, setIsProcessOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Correctly moved here
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isButtonSelected, setIsButtonSelected] = useState(false); // Nuevo estado
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [userId, setUserId] = useState<number | null>(null);

  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const userMenuRef  = useOutsideClick(() => {
  
  

  setIsUserMenuOpen(false);
  });
  
  const chatRef = useRef<HTMLDivElement>(null);
  //const notificationsRef = useRef<HTMLDivElement>(null);

  const notificationsRef  = useOutsideClick(() => {
  
    setIsNotificationsOpen(false);
    });
  
  const mobileRef = useOutsideClick(() => {
    setIsSidebarOpen(false);
    setIsNotificationsOpen(false);
  });

  const sidebarRef = useOutsideClick(() => {
   
    setIsNotificationsOpen(false);
    if (openSidebar) {
      setOpenSidebar(null);
    }
  });

  const topMenuItems = [ 
    { 
      label: "Inicio", 
      icon: <Home className="h-5 w-5 text-white" />, 
      subItems: ["Volver al Inicio", "Estadisticas"] 
    },
    { label: "Planificación", icon: null, subItems: ["Mis tareas", "Carta Gantt", "Vista General", "Tablero", "Permisología", "Resumen Permisología"] },
    { label: "Control de Documentos", icon: null, subItems: ["Carga de documentos", "Búsqueda rápida", "Glosario", "Reporte"] },
    { label: "Gestión", icon: null, subItems: ["Curva S", "Reporte semanal", "Adicionales", "Requerimientos de información"] }
  ];


  
  const sidebarItems = [
    { label: 'SUPERBANDEJA', subItems: ['Todas las tareas', 'Matriz de Eisenhower'] },
    { 
      label: 'ANALITICAS',
      subItems: [
        'Huella digital',        // Por proceso
        'Reporte de usuario y Departamento',  // Global
        'Reporte de progreso',   // Global
        'Rentabilidad',          // Global
        'Matriz de Riesgo'       // Global
      ]
    },
    { label: 'EVALUACIÓN TÉCNICO COMERCIAL', subItems: ['Metodología ETC', 'EPI Resumen','Resumen Evaluación Técnico Comercial'] },
    { label: 'TRANSMITTAL', subItems: ['Documentos emitidos a Obra', 'Registro de Transmittal'] },
    ...(isAdmin ? [{ label: 'ADMINISTRACIÓN', subItems: ['Crear proceso', 'Editar proceso', 'Crear usuarios', 'Editar usuarios', 'Mis plantillas', 'Mis planificaciones'] }] : [])
  ];
  
/*
  const allMenuItems = [
    { label: "Inicio", icon: <Home className="h-5 w-5" />, subItems: [] },
    { label: "Planificación", icon: null, subItems: ["Mis tareas", "Vista General", "Panel", "Permisología"] },
    { label: "Control de Documentos", icon: null, subItems: ["Búsqueda rápida", "Glosario", "Reporte"] },
    { label: "Gestión", icon: null, subItems: ["Curva S", "Reporte semanal", "EEPP", "Curva de Velocidad", "Adicionales"] },
    { label: 'SUPERBANDEJA', subItems: ['Todas las tareas', 'Matriz de Eisenhower'] },
    { label: 'ANALITICAS', subItems: ['Reporte de usuario y Departamento', 'Reporte de progreso', 'Huella digital', 'Rentabilidad'] },
    { label: 'METODOLOGÍA ETC', subItems: ['Cálculo de TIR', 'Resumen Evaluación Técnico Comercial'] },
    { label: 'TRANSMITAL', subItems: ['Documentos emitidos a Obra', 'Transmital'] },
    { label: 'ADMINISTRACIÓN', subItems: ['Crear'] }
  ];
*/
  const allMenuItems = [
  ...topMenuItems,
  ...sidebarItems,
  ];

  interface ProcessSelectProps {
    isVisible: boolean;
    selectedProcess: string;
    processes: Process[];
    isLoading: boolean;
    onChange: (processName: string) => void;
  }
  interface Process {
    codigo: string;
    nombreProceso: string;
    comuna: string;
  }
 
  interface Process {
    codigo: string;
    nombreProceso: string;
    comuna: string;
  }
  
  interface ProcessSelectProps {
    isVisible: boolean;
    selectedProcess: string;
    processes: Process[];
    isLoading: boolean;
    onChange: (value: string) => void;
  }
  
  const ProcessSelect: React.FC<ProcessSelectProps> = ({ 
    isVisible, 
    selectedProcess, 
    processes,
    isLoading,
    onChange 
  }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [filteredProcesses, setFilteredProcesses] = useState<Process[]>([]);
    const [displayValue, setDisplayValue] = useState<string>('');
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
  
    useEffect(() => {
      if (selectedProcess && processes.length > 0) {
        const currentProcess = processes.find((p: Process) => p.nombreProceso === selectedProcess);
        if (currentProcess) {
          const label = `${currentProcess.codigo} - ${currentProcess.nombreProceso} - ${currentProcess.comuna}`;
          setDisplayValue(label);
          setSearchTerm(label);
        }
      }
    }, [selectedProcess, processes]);
  
    useEffect(() => {
      const filtered = processes.filter((process: Process) => {
        const searchString = `${process.codigo} ${process.nombreProceso} ${process.comuna}`.toLowerCase();
        return searchTerm === '' || searchString.includes(searchTerm.toLowerCase());
      });
    
      if (selectedProcess) {
        const selectedProcessItem = filtered.find((p) => p.nombreProceso === selectedProcess);
        if (selectedProcessItem) {
          setFilteredProcesses([
            selectedProcessItem,
            ...filtered.filter((p) => p.nombreProceso !== selectedProcess),
          ]);
        } else {
          setFilteredProcesses(filtered);
        }
      } else {
        setFilteredProcesses(filtered);
      }
    }, [searchTerm, selectedProcess, processes]);
    
  
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
          setShowSuggestions(false);
          if (selectedProcess) {
            const currentProcess = processes.find((p: Process) => p.nombreProceso === selectedProcess);
            if (currentProcess) {
              const label = `${currentProcess.codigo} - ${currentProcess.nombreProceso} - ${currentProcess.comuna}`;
              setSearchTerm(label);
            }
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [selectedProcess, processes]);
  
    const handleProcessSelect = (process: Process) => {
      const label = `${process.codigo} - ${process.nombreProceso} - ${process.comuna}`;
      setSearchTerm(label);
      setDisplayValue(label);
      onChange(process.nombreProceso);
      setShowSuggestions(false);
      localStorage.setItem('lastSelectedProcess', process.nombreProceso);
    };
  
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearchTerm(value);
      setShowSuggestions(true);
    };
  
    const handleFocus = () => {
      setShowSuggestions(true);
      if (inputRef.current) {
        inputRef.current.select(); // Selecciona todo el texto automáticamente
      }
    };
  
    const clearSearch = () => {
      setSearchTerm('');
      setShowSuggestions(false);
    };
  
    if (!isVisible) return null;
  
    return (
      <div className="w-full mb-3" ref={wrapperRef}>
        <div className="font-bold mb-2">Proceso:</div>
        <div className="relative">
          <input
            ref={inputRef} // Referencia al input
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            onFocus={handleFocus} // Manejar el foco para seleccionar el texto
            placeholder="Buscar proceso..."
            className="w-full p-2 pr-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
            disabled={isLoading}
          />
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5 hover:scale-110 transition-transform cursor-pointer">
            <Search className="w-5 h-5" />
          </div>  
          {searchTerm && (
            <div className="absolute right-8 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5 hover:scale-110 transition-transform cursor-pointer" onClick={clearSearch}>
              <XCircle className="text-gray-400 w-5 h-5" />
            </div>
          )}
          {showSuggestions && (
            <div className="absolute z-50 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
              {filteredProcesses.length > 0 ? (
                filteredProcesses.map((process, index) => (
                  <div
                    key={index}
                    className={`flex items-center p-2 hover:bg-gray-100 cursor-pointer ${
                      selectedProcess === process.nombreProceso ? 'bg-blue-50' : ''
                    }`}
                    onClick={() => handleProcessSelect(process)}
                  >
                    <div className="font-medium mr-2 pr-2 border-r border-gray-300">{process.codigo}</div>
                    <div className="text-sm text-gray-600 mr-2 pr-2 border-r border-gray-300">{process.nombreProceso}</div>
                    <div className="text-xs text-gray-500">{process.comuna}</div>
                  </div>
                ))
              ) : (
                <div className="p-2 text-gray-500">No se encontraron procesos</div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
  
  
  
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const fetchProcesses = async () => {
      setIsLoadingProcesses(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type3`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Process[] = await response.json(); // Asume que `Process` es el tipo correcto
        setProcesses(data);
        
        // Recuperar el último proceso seleccionado del localStorage
        const lastSelectedProcess = localStorage.getItem('lastSelectedProcess');
        
        if (!isInitialized) {
          if (lastSelectedProcess && data.some((p: Process) => p.nombreProceso === lastSelectedProcess)) {
            setSelectedProcess(lastSelectedProcess);
          } else if (data.length > 0) {
            setSelectedProcess(data[0].nombreProceso);
            localStorage.setItem('lastSelectedProcess', data[0].nombreProceso);
          }
          setIsInitialized(true);
        }
      } catch (error) {
        console.error('Error fetching processes:', error);
      } finally {
        setIsLoadingProcesses(false);
      }
    };
  
    if (activeSection.main !== 'Inicio' && 
        activeSection.main !== 'Control de Documentos' && 
        activeSection.main !== 'TRANSMITTAL' && 
        activeSection.main !== 'ADMINISTRACIÓN') {
      fetchProcesses();
    }
  }, [activeSection.main, activeSection.sub, isInitialized]);
  


  const [isLoadingProcesses, setIsLoadingProcesses] = useState<boolean>(true);

  const fetchProcesses = async () => {
    setIsLoadingProcesses(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type3`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: Process[] = await response.json();
      setProcesses(data);
      
      // Solo establecemos el proceso seleccionado si no hay uno ya seleccionado
      if (!selectedProcess && data.length > 0) {
        setSelectedProcess(data[0].nombreProceso);
      }
    } catch (error) {
      console.error('Error fetching processes:', error);
    } finally {
      setIsLoadingProcesses(false);
    }
  };

  useEffect(() => {
    fetchProcesses();
  }, []);

  const handleProcessChange = (processName: string) => {
    setSelectedProcess(processName);
    localStorage.setItem('lastSelectedProcess', processName);
  };
  
  const handleTopMobileSelect = (main: string) => {
    console.log('handleTopMobileSelect called with:', main);
  
    if (main === 'Inicio') {
      setActiveSection({ main: 'Inicio', sub: '' });
      setIsMobileMenuOpen(false);
    } else {
      const menuItem = allMenuItems.find(item => item.label === main);
      if (menuItem && menuItem.subItems.length > 0) {
        // Toggle the menu if the same item is clicked again
        setOpenMobile(prev => (prev === main ? null : main));
      } else {
        setActiveSection({ main, sub: '' });
        setIsMobileMenuOpen(false);
      }
    }
  
    console.log('After handleTopMobileSelect:', { main, openMobile: openMobile });
  };
  

const handleSubMobileSelect = (main: string, sub: string) => {
  setActiveSection({ main, sub });
  
  //setIsMobileMenuOpen(false);  // Always close mobile menu after selection
};

/*


const handleSubMenuSelect = (main: string, sub: string) => {
  console.log('handleSubMenuSelect called with:', { main, sub });
  setActiveSection({ main, sub });
  setOpenMenu(null);
  setIsMobileMenuOpen(false);
  setIsSidebarOpen(false);
};
*/

const handleTopMenuSelect = (main: string, fromLogo: boolean = false) => {
  console.log('handleTopMenuSelect called with:', main);
  
  // Si el click viene del logo, ir directamente al inicio
  if (fromLogo) {
    setActiveSection({ main: 'Inicio', sub: '' });
    setIsMobileMenuOpen(false);
    return;
  }

  // Para el botón Inicio y otros menús, manejar el despliegue del menú
  const menuItem = allMenuItems.find(item => item.label === main);
  if (menuItem && menuItem.subItems.length > 0) {
    setOpenMenu(prev => prev === main ? null : main);
  } else {
    setActiveSection({ main, sub: '' });
    setIsMobileMenuOpen(false);
  }
};

const handleSubMenuSelect = (main: string, sub: string) => {
  if (main === 'Inicio') {
    if (sub === 'Volver al Inicio') {
      setActiveSection({ main: 'Inicio', sub: '' });
    } else if (sub === 'Estadisticas') {
      setActiveSection({ main: 'Inicio', sub: 'Estadisticas' });
    }
  } else {
    setActiveSection({ main, sub });
  }
  setOpenMenu(null);
  setIsMobileMenuOpen(false);
};

/*
  const handleSidebarSelect = (main: string, sub: string | null = null) => {
    setActiveSection({ main, sub: sub || '' });
  };
*/
const handleSidebarSelect = (main: string) => {
  console.log('handleSidebarSelect called with:', main);
  if (main === 'Inicio') {
    setActiveSection({ main: 'Inicio', sub: '' });
    setIsMobileMenuOpen(false);
  } else {
    const menuItem = allMenuItems.find(item => item.label === main);
    if (menuItem && menuItem.subItems.length > 0) {
      setOpenSidebar(prev => prev === main ? null : main); // Toggle open sidebar
    } else {
      setActiveSection({ main, sub: '' });
      setIsMobileMenuOpen(false);
    }
  }
  console.log('After handleSidebarSelect:', { main, openSidebar: openSidebar });
};
  

  
const handleSubSidebarSelect = (main: string, sub: string) => {
  setActiveSection({ main, sub });
  setOpenMenus(prev => ({ ...prev, [main]: !prev[main] })); // Toggle sub-menu open
  setIsMobileMenuOpen(false); // Always close mobile menu after selection
};

/*  const handleTopMobileSelect = (main: string) => {
    console.log('handleTopMenuSelect called with:', main);
    if (main === 'Inicio') {
      setActiveSection({ main: 'Inicio', sub: '' });
      setIsMobileMenuOpen(false);
    } else {
      const menuItem = allMenuItems.find(item => item.label === main);
      if (menuItem && menuItem.subItems.length > 0) {
        
          setOpenMenu(openMenu === main ? null : main);
        
      } else {
        setActiveSection({ main, sub: '' });
        setIsMobileMenuOpen(false);
      }
    }
    console.log('After handleTopMenuSelect:', { main, openMenu: openMenu });
  };
  const handleSubMobileSelect = (main: string, sub: string) => {
    setActiveSection({ main, sub });
    
   // setIsMobileMenuOpen(false);  // Always close mobile menu after selection
  };
*/


const getCurrentItems = () => {
  const currentItems = [...topMenuItems, ...sidebarItems];
  return currentItems.find(item => item.label === activeSection.main)?.subItems || [];
};

  const toggleMenu = (label: string | null) => {
    setOpenMenu(label === openMenu ? null : label);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
      setIsSidebarOpen(false);
    }
  };
  

  const handleNavigation = useCallback((direction: 'prev' | 'next') => {
    const currentItems = getCurrentItems();
    const currentIndex = currentItems.indexOf(activeSection.sub);
    let newIndex: number;

    if (direction === 'next') {
      newIndex = (currentIndex + 1) % currentItems.length;
    } else {
      newIndex = (currentIndex - 1 + currentItems.length) % currentItems.length;
    }

    setActiveSection(prev => ({ ...prev, sub: currentItems[newIndex] }));
  }, [activeSection.sub, getCurrentItems]);

  const navigationContextValue = {
    handleNavigation,
    getCurrentItems,
    activeSection,
  };



  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  
  useEffect(() => {
    // Obtener la variable de sesión almacenada
    const firstname = sessionStorage.getItem('firstName');

    // Verificar si 'firstname' es nulo o una cadena vacía
    if (!firstname) {
      // Redirigir al login si está vacío
      handleLogout();
   //alert('ho')
    }else{
     // alert('ha')
    }
    // Si no está vacío, se queda en la página actual
  }, [navigate]);


// Add event listener for ESC key
useEffect(() => {
  const handleEscKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isSidebarOpen) {
      setIsSidebarOpen(false);
      setIsMobileMenuOpen(false);
    }
  };
 
  
  
  const storedFirstName = sessionStorage.getItem('firstName');
  const storedTitle = sessionStorage.getItem('title');
  const storedLastName = sessionStorage.getItem('lastName');
  const storedEmail = sessionStorage.getItem('email');

  const storedIsAdmin = sessionStorage.getItem('isAdmin');
  setFirstName(storedFirstName);
  setLastName(storedLastName);
  setEmail(storedEmail);
  setTitle(storedTitle);
  setIsAdmin(storedIsAdmin === 'true');
  

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Element;
  
    if (sidebarRef.current && !sidebarRef.current.contains(target)) {
      setIsSidebarOpen(false);
    }
  
    if (userMenuRef.current && !userMenuRef.current.contains(target)) {
      setIsUserMenuOpen(false);
    }
  
    if (notificationsRef.current && !notificationsRef.current.contains(target)) {
      setIsNotificationsOpen(false);
    }
  };
  
 

  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 768);
  };



  
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    
    if (currentScrollY < lastScrollY) {
      setIsHeaderVisible(true);
    } else if (currentScrollY > 100 && currentScrollY > lastScrollY) {
      setIsHeaderVisible(false);
    }
    
    setLastScrollY(currentScrollY);
  };

  window.addEventListener('scroll', handleScroll, { passive: true });

  document.addEventListener('keydown', handleEscKey);

  return () => {
    document.removeEventListener('keydown', handleEscKey);
  };
}, [isSidebarOpen,isMobileMenuOpen]);


  const menuRef = useOutsideClick(() => {
    setOpenMenu(null);
  });

  const UserMenu: React.FC = () => (
    <div ref={userMenuRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 menu">
      <div className="py-1">
        <div className="px-4 py-0 text-sm text-gray-700">¡Hola, {firstName}!</div>
        <div className="px-4 text-sm text-red-500 text-xs">{isAdmin && <p>Administrador</p>}</div>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"> <Settings className="inline-block mr-2 h-4 w-4" />Configuración</a>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"><HelpCircle className="inline-block mr-2 h-4 w-4" />Ayuda</a>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"><Info className="inline-block mr-2 h-4 w-4" />Acerca de</a>
        <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <LogOut className="inline-block mr-2 h-4 w-4" />Salir
        </button>
      </div>
    </div>
  );

  const ChatWindow: React.FC = () => (
    <div ref={chatRef} className={`fixed right-4 ${isChatMinimized ? 'bottom-0' : 'bottom-4'} w-80 ${isChatMinimized ? 'h-10' : 'h-96'} bg-white rounded-lg shadow-lg z-50 flex flex-col transition-all duration-300`}>
      <div className="p-4 bg-custom-header text-white font-bold rounded-t-lg flex justify-between items-center">
        <span><i className="fa fa-eye w-5 h-5 mr-2"></i> Chat IA</span>
        <div className="flex items-center">
          <Minimize2 onClick={() => setIsChatMinimized(!isChatMinimized)} className="cursor-pointer mr-2" />
          <X onClick={() => setIsChatOpen(false)} className="cursor-pointer" />
        </div>
      </div>
      {!isChatMinimized && (
        <>
          <div className="flex-grow p-4 overflow-y-auto">
            {/* Chat messages would go here */}
            <p>Bienvenido a Simagi. Soy tu asistente IA ¿En qué puedo ayudarte?</p>
          </div>
          <div className="p-4 border-t">
            <input type="text" placeholder="Escribe un mensaje..." className="w-full p-2 border rounded" />
          </div>
        </>
      )}
    </div>
  );

  const NotificationsMenu: React.FC = () => (
    <div ref={notificationsRef} className="${isChatMinimized ? 'bottom-0' : 'bottom-4'} absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg z-50 max-h-96 overflow-y-auto menu">
      <div className="py-1">
        <div className="px-4 py-2 font-bold text-gray-700">Notificaciones</div>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <div className="font-bold">Nueva tarea asignada</div>
          <div className="text-xs text-gray-500">Hace 5 minutos</div>
        </a>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <div className="font-bold">Reunión programada</div>
          <div className="text-xs text-gray-500">Hace 1 hora</div>
        </a>
        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <div className="font-bold">Actualización de proyecto</div>
          <div className="text-xs text-gray-500">Ayer</div>
        </a>
      </div>
    </div>
  );





  const renderMenuItems = (items: Array<{ label: string; icon?: JSX.Element; subItems?: string[] }>) => (
    <ul>
      {items.map((item, index) => (
        <li key={index} onClick={() => handleSectionChange(item.label, '')}>
          {item.icon && <span>{item.icon}</span>}
          <span>{item.label}</span>
          {item.subItems && (
            <ul>
              {item.subItems.map((subItem, subIndex) => (
                <li key={subIndex} onClick={() => handleSectionChange(item.label, subItem)}>
                  {subItem}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );

  const CurrentComponent = componentMap[activeSection.main]?.[activeSection.sub] || componentMap['Inicio'][''];
  const handleSectionChange = (main: string, sub: string) => {
    setActiveSection({ main, sub });
    navigate(`/`); // Aquí debes poner la lógica para navegar a la ruta correcta
  };

 //https://simagi.cl/php/login/logout.php', {
  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/login/logout.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error en la respuesta del servidor:', errorText);
        return;
      }
  
      const data = await response.json();
  
      if (data.success) {
        localStorage.removeItem('authToken');
        sessionStorage.removeItem('authToken');
        navigate('/mvp');
      } else {
        console.error('Error en el logout:', data.error);
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  
  

  const navigateToSubComponent = (main: string, sub: string) => {
    setActiveSection({ main, sub });
    setOpenMenu(null);
    setIsMobileMenuOpen(false);
  };
  

  return (
    <NavigationContext.Provider value={navigationContextValue}>
    <div className="p-3 overflow-y-scroll h-screen w-screen fondo-imagen z-[9000] bg-gray-100" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      {/* Header */}
      <header className={`fixed top-0 left-0 right-0 bg-custom-header z-[900] shadow-md transition-all duration-300 ease-in-out  ${isHeaderVisible ? 'opacity-100' : 'opacity-0 -translate-y-full'}`}>
        <div className="mx-auto px-4 sm:px-6 lg:px-8 py-2">
          <div className="flex justify-between items-center">
            {/* Sidebar toggle button (visible only on desktop) */}
            <button 
              onClick={toggleSidebar}
              className="hidden md:flex left-0 top-0  p-2 rounded-xl hover:bg-custom-sidebar hover:text-white transition-colors duration-200"
            >
              <Menu className="text-white h-6 w-6" />
            </button>
            <div className="flex items-center">
              <div className="h-10 w-24 mr-4">
              <img 
                src={logoImage} 
                alt='Simagi' 
                className='cursor-pointer' 
                onClick={() => handleTopMenuSelect('Inicio', true)} 
                />
              </div>
{/*              <div className="relative hidden md:block">
                <input type="text" placeholder="Buscar" className="bg-gray-100 rounded-md py-2 px-4 pl-10 w-64" />
                <Search className="absolute left-3 top-2.5 text-gray-400 h-5 w-5" />
              </div>*/ }
            </div>
            {/* Desktop menu */}
            <nav className="hidden md:flex items-center" ref={menuRef}>
  {topMenuItems.map((item, index) => (
    <div key={index} className="relative group mx-4 cursor-pointer bg-custom-top-menu border-white border rounded-2xl">
      <div 
        className={`flex items-center px-4 py-2 ${openMenu === item.label ? 'bg-custom-sidebar text-white rounded-2xl' : 'hover:bg-custom-sidebar hover:text-white rounded-2xl'}`}
        onClick={() => handleTopMenuSelect(item.label)}
      >
        {item.icon}
        <span className="text-sm ml-1 text-white">{item.label}</span>
        {item.subItems.length > 0 && <ChevronDown className={`h-4 text-white w-4 ml-1 transition-transform duration-200 ${openMenu === item.label ? 'transform rotate-180' : ''}`} />}
      </div>
      {openMenu === item.label && item.subItems.length > 0 && (
        <div className="absolute z-10 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          {item.subItems.map((subItem, subIndex) => (
            <a
              key={subIndex}
              href="#"
              className={`block px-4 py-2 text-sm ${activeSection.sub === subItem ? 'bg-custom-sidebar text-white' : 'text-gray-700 hover:bg-custom-sidebar hover:text-white'}`}
              onClick={(e) => {
                e.preventDefault();
                handleSubMenuSelect(item.label, subItem);
              }}
            >
              {subItem}
            </a>
          ))}
        </div>
      )}
    </div>
  ))}
</nav>

            <div className="flex items-center space-x-4">
          <div className="relative">
            <Eye 
              className="h-6 w-6 text-white cursor-pointer hover:text-custom-sidebar" 
              onClick={() => setIsChatOpen(!isChatOpen)}
            />
            {isChatOpen && <ChatWindow />}
          </div>
          <div className="relative">
            <Bell 
              className="h-6 w-6 text-white cursor-pointer hover:text-custom-sidebar" 
              onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
            />
            {isNotificationsOpen && <NotificationsMenu />}
          </div>
          <div className="relative">
            <User 
              className="h-6 w-6 text-white cursor-pointer hover:text-custom-sidebar" 
              onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
            />
            {isUserMenuOpen && <UserMenu />}
          </div>
        

                    {/* Mobile menu toggle button */}
            <button
              className="md:hidden"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              <Menu className="h-6 w-6 text-white" />
            </button>
          </div>
        </div>

        </div>
      </header>
      <div className="h-16"></div>
     
      <div className="flex-grow flex">
        
        {/* Desktop Sidebar */}
        <aside
                    ref={sidebarRef}

          className={`sticky z-0 left-0 top-20 h-full  bg-custom-sidebar rounded text-white shadow-lg transition-all duration-300 ease-in-out z-10 ${
            isSidebarOpen ? 'md:w-64' : 'md:w-0'
          } overflow-hidden hidden md:block ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
        >
          <div className="h-full ">
            {sidebarItems.map((item, index) => (
              <div key={index} className="">
                <div
                  className={`py-2 px-4 cursor-pointer flex justify-between items-center rounded-md transition-all duration-800 ease-in-out
                    ${openSidebar === item.label ? 'bg-custom-light-sim text-cyan-700' : 'hover:bg-custom-header hover:text-white'}`}
                    onClick={() => handleSidebarSelect(item.label)}
                >
                  <span>{item.label}</span>
                  {item.subItems.length > 0 && (
                    <ChevronDown className={`h-4 w-4 transform transition-transform duration-200 ${openSidebar === item.label ? 'rotate-180' : ''}`} />
                  )}
                </div>
                {openSidebar === item.label && (
                  <div className="ml-4 mt-2">
                    {item.subItems.map((subItem, subIndex) => (
                      <div
                        key={subIndex}
                        className={`py-2 px-4 cursor-pointer rounded-md transition-all duration-200 ease-in-out
                          ${activeSection.sub === subItem ? 'bg-custom-light-sim text-cyan-700' : 'hover:bg-custom-header hover:text-white'}`}
                          onClick={() => handleSubSidebarSelect(item.label,subItem)}
                      >
                        {subItem}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </aside>
        
    {/* Mobile Menu */}
    <div className={`fixed inset-0 bg-gray-600 bg-opacity-75 z-20 transition-opacity md:hidden ${
          isMobileMenuOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        }`}>
          <div className="fixed inset-y-0 left-0 max-w-xs w-full bg-white shadow-xl z-30 overflow-y-auto transition transform ${
            isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
          }">
            <div className="p-4">
              <button
                className="absolute top-4 right-4 text-gray-600"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              {allMenuItems.map((item, index) => (
                <div key={index} className="mb-4">
                  <div
                    className={`py-2 px-4 cursor-pointer flex justify-between items-center rounded-md transition-all duration-800 ease-in-out
                      ${openMobile === item.label ? 'bg-custom-sidebar text-white' : 'hover:bg-blue-100 hover:text-custom-sidebar'}`}
                    onClick={() => handleTopMobileSelect(item.label)}
                  >
                    <span>{item.label}</span>
                    {item.subItems.length > 0 && (
                      <ChevronDown className={`h-4 w-4 transform transition-transform duration-200 ${openMobile === item.label ? 'rotate-180' : ''}`} />
                    )}
                  </div>
                  {openMobile === item.label && (
                    <div className="ml-4 mt-2">
                      {item.subItems.map((subItem, subIndex) => (
                        <div
                          key={subIndex}
                          className={`py-2 px-4 cursor-pointer rounded-md transition-all duration-200 ease-in-out
                            ${activeSection.sub === subItem ? 'bg-custom-sidebar text-white' : 'hover:bg-custom-sidebar hover:text-white'}`}
                          onClick={() => handleSubMenuSelect(item.label, subItem)}
                        >
                          {subItem}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          
        </div>
  
      {/* Main Content */}
      {(activeSection.main !== 'Inicio' || activeSection.sub === 'Estadisticas') && (

        <div className={`w-full overflow-auto max-w-screen lg:pr-3 lg:pl-3 px-2 sm:px-6 lg:px-4 py-2 `}>
<div className={`w-full flex flex-col`}>
    <div className="w-full items-center">
    <ProcessSelect 
  isVisible={
    (activeSection.main === 'ANALITICAS' && 
     activeSection.sub === 'Huella digital') ||
    (activeSection.main !== 'Inicio' && 
     activeSection.main !== 'Control de Documentos' && 
     activeSection.main !== 'TRANSMITTAL' && 
     activeSection.main !== 'ADMINISTRACIÓN' && 
     activeSection.main !== 'SUPERBANDEJA' &&
     activeSection.main !== 'ANALITICAS')
  }
  selectedProcess={selectedProcess}
  processes={processes}
  isLoading={isLoadingProcesses}
  onChange={handleProcessChange}
/>
    </div>
    <main
      className={` p-8 flex-1 mt-3 shadow-md overflow-hidden bg-white rounded-xl transition-all duration-300 ease-in-out ${isSidebarOpen ? 'lg:ml-0' : 'lg:ml-0'}`}
    >
      <h2 className="text-xl font-bold text-gray-800 mb-4 uppercase">
      {activeSection.main} {"➤"} {activeSection.sub}

      </h2>

       {/* Navigation arrows */}
       <NavigationArrows />

          {/* Main Content */}
          <div className={`flex-grow overflow-auto`}>
{(() => {
  // Para debugging
 // console.log('Current section:', activeSection.main, activeSection.sub);
 // console.log('Component to render:', componentMap[activeSection.main]?.[activeSection.sub]);

  const mainSection = activeSection.main.toUpperCase();
  const renderComponent = (Component: React.ComponentType<any>) => (
    <Component
      selectedProcess={selectedProcess}
      setSelectedProcess={setSelectedProcess}
      processes={processes}
      navigateToSubComponent={navigateToSubComponent}
    />
  );

  // Primero intentamos con el nombre exacto
  if (componentMap[activeSection.main] && componentMap[activeSection.main][activeSection.sub]) {
    return renderComponent(componentMap[activeSection.main][activeSection.sub]);
  }
  // Luego intentamos con el nombre en mayúsculas
  else if (componentMap[mainSection] && componentMap[mainSection][activeSection.sub]) {
    return renderComponent(componentMap[mainSection][activeSection.sub]);
  } else {
    return (
      <p className="text-gray-600">
        Contenido no disponible para {activeSection.sub} en {activeSection.main}.
      </p>
    );
  }
})()}
</div>
      {/* Page indicators */}
      <div className="flex justify-center mt-10">
        <div className="flex items-center space-x-2">
          {getCurrentItems().map((_, index) => (
            <div
              key={index}
              className={`h-2 w-2 rounded-full ${index === getCurrentItems().indexOf(activeSection.sub) ? 'bg-custom-header' : 'bg-gray-300'}`}
            />
          ))}
        </div>
      </div>
    </main>
  </div>
</div>
)}

    </div>
    </div>
    </NavigationContext.Provider>

  );
};

export default SimagiInteractivePreview;