import React from 'react';

const FinancialProjectTable = () => {
  const periods = ['18 MESES', '24 MESES', '30 MESES', '36 MESES', '42 MESES'];

  const salesData: Record<string, number[]> = {
    'Ventas Departamentos': [474371.9, 476359.9, 478347.9, 480336.0, 484312.3],
    'Locales': [0.0, 0.0, 0.0, 0.0, 0.0],
    'Oficinas': [0.0, 0.0, 0.0, 0.0, 0.0],
    'Estacionamientos Subterráneos': [30666.0, 30672.3, 30678.6, 30684.8, 30697.1],
    'Bodegas': [3382.3, 3383.0, 3383.7, 3384.4, 3385.7],
  };

  const expensesData: Record<string, number[]> = {
    'Terreno UF': [148512.0, 148512.0, 148512.0, 148512.0, 148512.0],
    'Construcción': [190995.3, 190995.3, 190995.3, 190995.3, 190995.3],
    'Otros Construcción': [2225.3, 2225.3, 2225.3, 2225.3, 2225.3],
    'Urbanización': [0.0, 0.0, 0.0, 0.0, 0.0],
    'Honorarios Especialistas': [9270.9, 9274.9, 9278.8, 9282.8, 9290.8],
    'Derechos y Empalmes': [1327.3, 1327.3, 1327.3, 1327.3, 1327.3],
  };

  const marketingAdminData: Record<string, number[]> = {
    'Publicidad & Marketing y Brooker Digital': [3442.1, 3707.3, 4174.5, 4443.6, 4928.8],
    'Comercialización': [12202.1, 12250.0, 12297.8, 12345.7, 12441.5],
    'Gestión y Administración': [15252.6, 15312.5, 15372.3, 15432.2, 15551.9],
    'Postventa': [2898.3, 2898.3, 2898.3, 2898.3, 2898.3],
    'Seguro Venta en verde': [321.5, 243.2, 195.9, 163.4, 140.3],
    'Imprevistos': [2898.3, 2898.3, 2898.3, 2898.3, 2898.3],
    'Gastos Comunes y Contribuciones': [3316.1, 4009.0, 5097.7, 6349.4, 7733.8],
    'Pago de IVA': [17332.7, 17642.1, 17951.5, 18261.0, 18879.8],
  };

  const profitCalculations: Record<string, (number | string)[]> = {
    'UTILIDAD a/INT e IMPTO': [98425.7, 99119.7, 99185.0, 99270.5, 100571.7],
    'MARGEN PROYECTO': ['23.2%', '23.3%', '23.3%', '23.6%', '24.1%'],
    'Gastos Financieros': [19614.9, 19614.9, 20205.8, 22248.3, 24558.7],
    'UTILIDAD NETA a/IMPUESTO': [78810.8, 79504.9, 78979.2, 77022.2, 76013.1],
    'MARGEN a/IMPTO': ['15.5%', '15.6%', '15.4%', '15.0%', '14.7%'],
    'IMPUESTO': [21278.9, 21466.3, 21324.4, 20796.0, 20523.5],
    'UTILIDAD NETA': [57531.9, 58038.6, 57654.8, 56226.2, 55489.5],
    'MARGEN NETO': ['11.3%', '11.4%', '11.3%', '10.9%', '10.7%'],
  };

  const financialIndicators: Record<string, (number | string)[]> = {
    'TIR del Proyecto PURO': ['17.3%', '15.2%', '13.6%', '12.0%', '10.9%'],
    'TIR del Proyecto FINANCIADO': ['36.0%', '27.0%', '21.7%', '18.1%', '15.5%'],
    'Inversión Socios en UF': [77208.7, 77208.7, 77208.7, 77208.7, 77208.7],
    'Utilidad de Mercado (12%)': [9265.0, 9265.0, 9265.0, 9265.0, 9265.0],
    'Premio por Riesgo': [89160.7, 89854.7, 89919.9, 90005.4, 91306.7],
    'Utilidad sobre Inversión (%)': ['102.1%', '103.0%', '102.3%', '99.8%', '98.5%'],
    'Utilidad sobre Inversión/año': ['68.1%', '51.5%', '40.9%', '33.3%', '28.1%'],
    'Prestamo Banco en UF': [193220.6, 193220.6, 193220.6, 193220.6, 193220.6],
    'Duración Construcción meses': [15.0, 15.0, 15.0, 15.0, 15.0],
    'Velocidad de Venta meses': [18.0, 24.0, 30.0, 36.0, 42.0],
    '% Venta Término Construcción': ['100%', '90%', '80%', '70%', '50%'],
    '% TERRENO / VENTA': ['29.2%', '29.1%', '29.0%', '28.9%', '28.6%'],
    'Incidencia Terreno (UF/m2)': [42.5, 42.5, 42.5, 42.5, 42.5],
    'Utilidad a/Impto sobre Intereses': [5.0, 5.1, 4.9, 4.5, 4.1],
  };

  const formatNumber = (num: number): string => {
    return num
      .toLocaleString('es-CL', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })
      .replace('.', ',');
  };

  const renderRows = (
    data: Record<string, (number | string)[]>,
    isNumber: boolean = true
  ) => {
    return Object.entries(data).map(([category, values]) => (
      <tr key={category}>
        <td className="border p-2">{category}</td>
        {values.map((value, idx) => (
          <td key={idx} className="border p-2 text-right">
            {isNumber && typeof value === 'number'
              ? formatNumber(value)
              : value}
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <div className="w-full max-w-6xl p-4">
      <div className="text-lg font-bold mb-4">Nombre Proyecto: 34.0 UNIDADES</div>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-blue-100">
              <th className="border p-2 text-left">PLAZO VENTA</th>
              {periods.map((period) => (
                <th key={period} className="border p-2 text-right">
                  {period}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="bg-blue-50">
              <td colSpan={6} className="border p-2 font-bold">
                INGRESOS
              </td>
            </tr>
            {renderRows(salesData)}
            <tr className="bg-blue-50 font-bold">
              <td className="border p-2">Total Ventas</td>
              {periods.map((_, idx) => (
                <td key={idx} className="border p-2 text-right">
                  {formatNumber(
                    Object.values(salesData).reduce(
                      (acc, values) => acc + values[idx],
                      0
                    )
                  )}
                </td>
              ))}
            </tr>
            <tr className="bg-blue-50">
              <td colSpan={6} className="border p-2 font-bold">
                EGRESOS
              </td>
            </tr>
            {renderRows(expensesData)}
            <tr className="bg-blue-50">
              <td colSpan={6} className="border p-2 font-bold">
                Costos Comercialización y Administración
              </td>
            </tr>
            {renderRows(marketingAdminData)}
            <tr className="bg-blue-50">
              <td colSpan={6} className="border p-2 font-bold">
                UTILIDADES
              </td>
            </tr>
            {renderRows(profitCalculations, false)}
            <tr className="bg-blue-100">
              <td colSpan={6} className="border p-2 font-bold">
                INDICADORES FINANCIEROS a/Impuestos
              </td>
            </tr>
            {renderRows(financialIndicators, false)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FinancialProjectTable;
