import React, { useState } from 'react';
import axios from 'axios';
import '../App.css'; // Asegúrate de tener un archivo CSS adecuado para RequestPasswordReset
import '@fortawesome/fontawesome-free/css/all.min.css';

interface RequestPasswordResetProps {
    onClose: () => void;
}

const RequestPasswordReset: React.FC<RequestPasswordResetProps> = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/php/pswd/request_reset.php`, { email });

            if (response.data.success) {
                setMessage('Si el correo electrónico existe, se ha enviado un enlace para restablecer la contraseña.');
            } else {
                setMessage(`Error: ${response.data.error}`);
            }
        } catch (error) {
            setMessage('Error al enviar el correo de restablecimiento.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
            <button className="popup-close" onClick={onClose}><i className="text-black fa fa-xmark w-5 h-5 mr-2"></i></button>
                <h1><b>¿Olvidaste tu contraseña?</b></h1>
                <form onSubmit={handleSubmit}>
                    <label>
                        Ingresa tu correo electrónico para recuperar el acceso a Simagi.
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </label>
                    <button type="submit" className='submit-button' disabled={loading}>
                        {loading ? 'Enviando...' : 'Enviar Enlace de Restablecimiento'}
                    </button>
                </form>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default RequestPasswordReset;
