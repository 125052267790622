import React, { useState, useCallback, ChangeEvent, DragEvent, useEffect } from 'react';
import { Edit, Edit2, Paperclip, PlusCircle, TrashIcon, Undo } from 'lucide-react';
import axios from 'axios';


const Select: React.FC<{
  label: string;
  value: string;
  onChange: (value: string) => void;
  options: { value: string; label: string }[];
}> = ({ label, value, onChange, options }) => (
  <div className="mb-2">
    <label className="block text-sm font-medium mb-1">{label}</label>
    <select
      className="border rounded px-2 py-1 w-full text-sm"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="">Seleccione una opción</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

const TextArea: React.FC<{
  label: string;
  value: string;
  onChange: (value: string) => void;
}> = ({ label, value, onChange }) => (
  <div className="mb-2">
    <label className="block text-sm font-medium mb-1">{label}</label>
    <textarea
      className="border rounded px-2 py-1 w-full h-16 resize-none text-sm"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);
const Dropzone: React.FC<{
    label: string;
    files: File[];
    onFilesChange: (files: File[]) => void;
    onRemoveFile: (fileName: string, type: 'principal' | 'complementario') => void;
    type: 'principal' | 'complementario';
  }> = ({ label, files, onFilesChange, onRemoveFile, type }) => {
    const fileInputRef = React.createRef<HTMLInputElement>();
    const [isDragging, setIsDragging] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
  
    const handleDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(false);
      const droppedFiles = Array.from(event.dataTransfer.files);
      if (droppedFiles.length > 0) {
        setIsUploading(true);
        setTimeout(() => {
          onFilesChange([...files, ...droppedFiles]);
          setIsUploading(false);
        }, 1000);
      }
    }, [files, onFilesChange]);
  
    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(true);
    };
  
    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(false);
    };
  
    const handleFileSelect = () => {
      fileInputRef.current?.click();
    };
  
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = Array.from(event.target.files || []);
      if (selectedFiles.length > 0) {
        setIsUploading(true);
        setTimeout(() => {
          onFilesChange([...files, ...selectedFiles]);
          setIsUploading(false);
        }, 1000);
      }
    };


    return (
      <div className="flex flex-col items-center">
        {files.length === 0 && (
          <div
            className={`border-2 ${isDragging ? 'border-blue-500' : 'border-dashed border-gray-300'} rounded-lg p-6 w-full max-w-4xl flex flex-col items-center justify-center ${isUploading ? 'bg-gray-100' : ''}`}
            style={{ minHeight: '200px' }} // Ajusta la altura mínima según tus necesidades
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onClick={handleFileSelect}
          >
            {isUploading ? (
              <div className="flex flex-col items-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 mb-2"></div>
                <p>Cargando...</p>
              </div>
            ) : (
              <>
                <p className="text-center text-lg font-semibold mb-2">{label}</p>
                <p className="text-center text-sm text-gray-400">Arrastra y suelta los archivos aquí o haz clic para seleccionar</p>
                <input
                  ref={fileInputRef}
                  type="file"
                  className="hidden"
                  multiple
                  onChange={handleFileChange}
                />
              </>
            )}
          </div>
        )}
        {files.length > 0 && (
          <div className="mt-4 w-full max-w-4xl">
            <h4 className="font-medium mb-2 text-center">Archivos cargados:</h4>
            <div className="space-y-2">
              {files.map((file) => (
                <div key={file.name} className="flex items-center justify-between p-2 border rounded bg-gray-50">
                  <span className="text-sm">{file.name}</span>
                  <div className="flex items-center">
                   
                 
                   
                    <button
                      onClick={() => onRemoveFile(file.name, type)}
                      className="text-red-500 hover:text-red-600 mr-2"
                    >
                      <TrashIcon className="w-5 h-5" />
                    </button>
                    <Paperclip className="w-5 h-5" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };
  
  interface Option {
    value: string;
    label: string;
  }

  interface Nivel {
    nivel_id: number;
    titulo: string;
  }

  interface Data {
    nivel_id: number;
    concepto: string;
    nomenclatura: string;
  }

const FormularioSubidaDocumentos: React.FC = () => {
  const [proyectoSeleccionado, setProyectoSeleccionado] = useState<string>('');
  const [proyectos, setProyectos] = useState<Option[]>([]);
  const [username, setUsername] = useState<string | null>(null);
  const [tituloDocumento, setTituloDocumento] = useState('');
  const [codigo, setCodigo] = useState<string>('');
  const [correlativo, setCorrelativo] = useState('');
  const [revision, setRevision] = useState<string>('');
  const [version, setVersion] = useState<number>(1);
  const [newRevision, setNewRevision] = useState<string>('');
  const [newVersion, setNewVersion] = useState<number>(1);
  const [comentarios, setComentarios] = useState('');
  const [archivos, setArchivos] = useState<File[]>([]);
  const [archivosComplementarios, setArchivosComplementarios] = useState<File[]>([]);
  const [documentosCargados, setDocumentosCargados] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [niveles, setNiveles] = useState<Nivel[]>([]);
  const [data, setData] = useState<Data[]>([]);
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});
  const [labelMessage, setLabelMessage] = useState<string>(''); // Cambiado a estado
  const [existsRevision, setExistsRevision] = useState<boolean>(false);
  const [existsDocument, setExistsDocument] = useState<boolean>(false);
  const [isSubVersion, setIsSubVersion] = useState<number>(0);

  interface Documento {
    proyecto?: string;
    codigo?: string;
    username?: string;
    titulo?: string;
    correlativo?: string;
    revision?: string;
    version?: number;
    comentarios?: string;
    archivos: File[]; // Define archivos como un array de objetos File
    niveles: string;
    selectedValues: Record<string, any>; // Ajusta el tipo según la estructura de selectedValues
  }

  const [responseMessage, setResponseMessage] = useState<string>('');

  const enviarDatos = async () => {
    try {
      const formData = new FormData();
      
      // Itera sobre cada documento en documentosCargados
      documentosCargados.forEach((documento, index) => {
        // Agrega los datos del documento a formData
     //  alert(documento.isSubVersion);
        formData.append(`documentos[${index}][proyecto]`, documento.proyecto);
        formData.append(`documentos[${index}][codigo]`, documento.codigo);
        formData.append(`documentos[${index}][username]`, documento.username);
        formData.append(`documentos[${index}][titulo]`, documento.titulo);
        formData.append(`documentos[${index}][correlativo]`, documento.correlativo);
        formData.append(`documentos[${index}][revision]`, documento.revision);
        formData.append(`documentos[${index}][version]`, documento.version);
        formData.append(`documentos[${index}][comentarios]`, documento.comentarios.toUpperCase());
        formData.append(`documentos[${index}][niveles]`, JSON.stringify(documento.niveles));
        formData.append(`documentos[${index}][selectedValues]`, JSON.stringify(documento.selectedValues));
        formData.append(`documentos[${index}][subversion]`, documento.isSubVersion);
       // alert(documento.version);
        // Verifica si el documento tiene archivos
        if (documento.archivos) {
          // Itera sobre cada archivo en el documento
          documento.archivos.forEach((archivo: File) => {
            // Usa un nombre único para cada archivo basado en el índice del documento
            formData.append(`archivo[${index}]`, archivo);
          });
        }
      });
   //   alert(JSON.stringify(formData));
      // Envía la solicitud HTTP POST con los datos del formulario
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/cargardocumento/guardar_documento.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('Respuesta del servidor:', response.data);
      setResponseMessage('Documentos cargados exitosamente'); 
       
    } catch (error) {
      console.error('Error en la solicitud HTTP:', error);
      setResponseMessage('Error al cargar documentos');
    }
  };
  
  useEffect(() => {
    if (responseMessage) {
      const timer = setTimeout(() => setResponseMessage(''), 5000); // Limpiar mensaje después de 5 segundos
      setDocumentosCargados([]);
      setLabelMessage('');
      setNewRevision('');
      setExistsDocument(false);
      setNewVersion(1);
      return () => clearTimeout(timer);
      
    }
  }, [responseMessage]);

  useEffect(() => {
    if (labelMessage) {
      //alert(labelMessage);
    }
  }, [labelMessage]);

   // Función para manejar la pérdida de foco en el campo "Revisión"
   const handleBlurRevision = async () => {
    if (!revision) {
      return; // No hacer nada si ya se ha validado
    }
    
   // setIsRevisionFocused(true); // Marcar como validado

    const codigoGenerado = generarCodigo();
    if (!codigoGenerado) {
        //alert('Código generado vacío');
        return;
    }
    
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/cargardocumento/compararCodigo.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ codigo: codigoGenerado, revision: revision })
        });
     
          const data = await response.json();
        
        const {
          titulo,
          existsDocument,
          existsRevision,
          revision: oldRevision,
          version: oldVersion,
          prohibited_revisions: prohibitedRevisions,
          new_revision: newRevision,
          new_version: newVersion,
          revision_allowed: revisionAllowed
      } = data;
      //alert(JSON.stringify(data));
      setExistsDocument(existsDocument);
      setTituloDocumento(existsDocument?titulo:tituloDocumento);
      setExistsRevision(existsDocument);
      setLabelMessage(existsDocument?existsRevision?`El documento ya existe y se encuentra en la revisión ${oldRevision} versión ${oldVersion}. Si continúa, el documento mantendrá su revisión en ${newRevision} y aumentará su versión a ${newVersion}`:`El documento ya existe y se encuentra en la revisión ${oldRevision} versión ${oldVersion}. Si continúa, el documento cambiará la revisión a ${newRevision} versión ${newVersion}`:`El documento se ingresará con revisión ${newRevision} versión ${newVersion}`)
      setNewRevision(newRevision);
      setNewVersion(newVersion);
      setIsSubVersion(0);
      
     
    } catch (error) {
        console.error('Error al verificar el código:', error);
        //alert('Error al verificar el código.');
    }
  };


  const generarCodigo = () => {
    // Aquí puedes construir tu código basado en el correlativo y otros valores si es necesario
    if (codigo && correlativo) {
      let nivelesTrama = ''; // Usa let para poder modificar el valor
  
      niveles.forEach(nivel => {
        // Asegúrate de que selectedValues[nivel.nivel_id] esté definido
        if (selectedValues[nivel.nivel_id]) {
          nivelesTrama += `${selectedValues[nivel.nivel_id]}-`; // Usa template literals para concatenar strings
        }
      });
  
      // Elimina el guion final si existe
      if (nivelesTrama.endsWith('-')) {
        nivelesTrama = nivelesTrama.slice(0, -1); // Elimina el último carácter
      }
      
      // Retorna el código como una cadena
      return `${codigo}-${nivelesTrama}-${correlativo}`;
    }
    
    // Retorna un valor predeterminado o vacío si las condiciones no se cumplen
    return '';
  };
  
  


  const manejarEnvio = () => {
    if (archivos.length === 0) {
        return; // No hacer nada si no hay archivos
    }
    if (!areFieldsComplete()) {
      return; // No hacer nada si los campos no están completos
    }
    const nuevoDocumento = {
      proyecto: proyectoSeleccionado,
      username,
      titulo: tituloDocumento,
      codigo,
      correlativo,
      niveles,
      selectedValues,
      revision: newRevision,
      version: newVersion,
      comentarios,
      archivos,
      isSubVersion

    };

  
    if (editIndex !== null) {
      // Actualizar el documento existente
      const updatedDocumentosCargados = [...documentosCargados];
      updatedDocumentosCargados[editIndex] = nuevoDocumento;
      setDocumentosCargados(updatedDocumentosCargados);
      setEditIndex(null); // Resetear el índice de edición
    } else {
      // Agregar un nuevo documento
      setDocumentosCargados([...documentosCargados, nuevoDocumento]);
    }

    setProyectoSeleccionado('');
    setTituloDocumento('');
    setCorrelativo('');
    setRevision('');
    setVersion(1);
    setComentarios('');
    setArchivos([]);
    setExistsDocument(false);
    setArchivosComplementarios([]);
    setSelectedValues({});
    setLabelMessage('');
    setIsSubVersion(0);
  };


  const handleRemoveFile = (fileName: string, type: 'principal' | 'complementario') => {
    if (type === 'principal') {
      setArchivos(archivos.filter(file => file.name !== fileName));
    } else {
      setArchivosComplementarios(archivosComplementarios.filter(file => file.name !== fileName));
    }
  };

  const handleRemoveDocument = (index: number) => {
    setDocumentosCargados(documentosCargados.filter((_, i) => i !== index));
  };

  const handleRemoveAllFiles = () => {
    setArchivos([]);
    setArchivosComplementarios([]);
    setDocumentosCargados([]); // Limpiar los documentos cargados
};

  const openModal = (files: File[]) => {
    setSelectedFiles(files);
    setModalOpen(true);
  };



 // Verifica si todos los campos requeridos están llenos
 const areFieldsComplete = () => {
  return (
    proyectoSeleccionado !== '' &&
    tituloDocumento !== '' &&
    Object.values(selectedValues).every(value => value.trim() !== '' && !value.includes('Seleccione')) &&
    codigo !== '' &&
    correlativo !== '' &&
    archivos.length > 0
  );
};





  useEffect(() => {
    // Fetch niveles from the backend
    const fetchNiveles = async () => {
      try {
        const response = await axios.get<Nivel[]>(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/get_levels.php`);
        setNiveles(response.data);
      } catch (error) {
        console.error('Error fetching niveles:', error);
      }
    };

    const fetchData = async () => {
      try {
        const response = await axios.get<Data[]>(`${process.env.REACT_APP_API_URL}/php/pages/controldocument/glosario/get_levels_data.php`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchNiveles();
    fetchData();
  }, []);

  const handleSelectChange = (nivelId: number, value: string) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [nivelId]: value,
      
    }));
  
  };

    // Function to get options based on nivel_id
    const getOptionsForNivel = (nivelId: number) => {
      return data
        .filter(item => item.nivel_id === nivelId)
        .map(item => (
          <option key={item.concepto} value={item.nomenclatura}>
            {`${item.nomenclatura} - ${item.concepto}`}
          </option>
        ));
    };
  


  const closeModal = () => setModalOpen(false);
  const cargarTexto = documentosCargados.length > 1 ? 'Cargar Documentos' : 'Cargar Documento';

  const fetchProcesses = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/php/pages/proceso/get_processes.php?type=type2`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: string[] = await response.json();
      const formattedData: Option[] = data.map((process: string) => ({
        value: process,
        label: process,
      }));
      setProyectos(formattedData);
      
      
    } catch (error) {
      console.error('Error fetching processes:', error);
    }
  };
  

  const handleEditDocument = (index: number) => {
    const documentToEdit = documentosCargados[index];
    setProyectoSeleccionado(documentToEdit.proyecto);
    setTituloDocumento(documentToEdit.titulo);
    setCodigo(documentToEdit.codigo);
    setCorrelativo(documentToEdit.correlativo);
    setRevision(documentToEdit.revision);
    setComentarios(documentToEdit.comentarios);
    setArchivos(documentToEdit.archivos);
    setArchivosComplementarios(documentToEdit.archivosComplementarios);
    setSelectedValues(documentToEdit.selectedValues);
    setEditIndex(index); // Establecer el índice de edición
  };





  useEffect(() => {
    fetchProcesses();
  }, []);

   // Recuperar el nombre de usuario desde sessionStorage cuando el componente se monte
   useEffect(() => {
    const storedFirstName = sessionStorage.getItem('firstName');
    const storedLastName = sessionStorage.getItem('lastName');
    
    if (storedFirstName && storedLastName) {
      setUsername(`${storedFirstName} ${storedLastName}`);
    }
  }, []);
  
// Función para actualizar el código con el texto anterior al primer espacio en blanco
useEffect(() => {
  if (proyectoSeleccionado) {
    // Encuentra el índice del primer espacio en blanco
    const indexOfSpace = proyectoSeleccionado.indexOf(' ');
    // Si hay un espacio en blanco, toma el texto antes de él; de lo contrario, usa el texto completo
    const newCodigo = indexOfSpace !== -1
      ? proyectoSeleccionado.slice(0, indexOfSpace)
      : proyectoSeleccionado;
    setCodigo(newCodigo);
  } else {
    setCodigo('');
  }
}, [proyectoSeleccionado]);

  // Extrae los encabezados dinámicos de los niveles
  const encabezados = niveles.map(nivel => nivel.titulo);
  
  return (
    <div className="bg-white rounded-lg p-4">
      <div className="grid grid-cols-2 gap-2">
      <Select
        label="Proyecto*"
        value={proyectoSeleccionado}
        onChange={setProyectoSeleccionado}
        options={proyectos}  // Now the options are correctly formatted
      />
      <div>
          <label className="block text-sm font-medium mb-1">Emitido por*</label>
          <input
            className="border rounded px-2 py-1 w-full text-sm  cursor-not-allowed"
            type="text"
            value={username || ''}
            disabled
           
            
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Título del documento*</label>
          <input
            className="border rounded px-2 py-1 w-full text-sm"
            type="text"
            value={tituloDocumento.toUpperCase()}
            onChange={(e) => setTituloDocumento(e.target.value.toUpperCase())}
            disabled={existsDocument}
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Código</label>
          <input
            className="border rounded px-2 py-1 w-full text-sm  cursor-not-allowed"
            type="text"
            value={codigo}
            disabled
          />
        </div>
        {niveles.map(nivel => (
          <div key={nivel.nivel_id}>
            <label htmlFor={`select-${nivel.nivel_id}`} className="block text-sm font-medium mb-1">
              {nivel.titulo}
            </label>
            <select
              id={`select-${nivel.nivel_id}`}
              value={selectedValues[nivel.nivel_id] || ''}
              onChange={(e) => {handleSelectChange(nivel.nivel_id, e.target.value)

                
              } }
              className="border rounded px-2 py-1 w-full text-sm"
            >
              <option value="">Seleccione {nivel.titulo}</option>
              {getOptionsForNivel(nivel.nivel_id)}
            </select>
          </div>
        ))}
        <div>
          <label className="block text-sm font-medium mb-1">Correlativo</label>
          <input
            className="border rounded px-2 py-1 w-full text-sm"
            type="text"
            value={correlativo}
            onChange={(e) =>{

              const value = e.target.value.replace(/\D/g, "");
              setCorrelativo(value.slice(0,3))
             
            }
              
            }
             
          />
        </div>
        <div>
          <label className="block w-full text-sm font-medium mb-1">Revisión</label>
          <input
  className="border rounded px-2 py-1 w-full text-sm"
  type="text"
  value={revision}
  onChange={(e) => {
    const input = e.target.value.toUpperCase();

    // Verificar si el input contiene letras y números
    const hasLetters = /[A-Z]/.test(input);
    const hasNumbers = /\d/.test(input);

    let newValue = input;

    // Si contiene letras, permitir solo letras y limitar a un carácter
    if (hasLetters) {
      newValue = input.replace(/[^A-Z]/g, "").slice(0, 1);
    }

    // Si contiene números, permitir solo números y sin límite
    if (hasNumbers) {
      newValue = input.replace(/[^0-9]/g, "");
    }

    setRevision(newValue);
  }}
  onBlur={handleBlurRevision}
/>

        
        </div>
        
        <div className="col-span-2  w-full justify-start">
        <div className="w-full inline-block mt-4">
        {labelMessage && (
          <div 
            className={`p-4 inline-block rounded mb-4 text-white ${
              existsRevision ?   'bg-yellow-200 text-yellow-800' : 'bg-green-200 text-teal-700'
            }`}
          >
            {labelMessage}
          </div>
        )}
      </div>  
      <TextArea
        label="Comentarios"
        value={comentarios.toUpperCase()}
        onChange={setComentarios}
      />
      <div className="mt-10 col-span-2  w-full justify-center">
      <Dropzone
  label="Agregar documento"
  files={archivos}
  onFilesChange={setArchivos}
  onRemoveFile={handleRemoveFile}
  type="principal"
  
   
/> </div>
      </div>
     

         <div className="col-span-2 mt-4 flex justify-center">
          <button
            className={`bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 ${
              !areFieldsComplete()
                  ? 'bg-gray-400 text-gray-600 cursor-not-allowed  hover:bg-gray-400'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              }`}
            onClick={manejarEnvio}
            disabled={!areFieldsComplete()}
          >
           {editIndex !== null ? (
    <>
      <Edit className='inline-block mr-2' />
      Modificar
    </>
  ) : (
    <>
      <PlusCircle className='inline-block mr-2' />
      Añadir
    </>
  )}
          </button>
          <button
      onClick={() => {
        setProyectoSeleccionado('');
        setTituloDocumento('');
        setCorrelativo('');
        handleRemoveAllFiles();
        setRevision('');
        setComentarios('');
        setArchivos([]);
        setSelectedValues({});
        setArchivosComplementarios([]);
        setLabelMessage('');
        setIsSubVersion(0);
      }}
      className="bg-gray-500 ml-3 text-white px-4 py-2 rounded hover:bg-gray-600"
    >
      <Undo className='inline-block mr-2' />Reiniciar Carga
    </button>
  
        </div>
      </div>
    
      {documentosCargados.length > 0 && (

        <div className="mt-4">
          <h2 className="text-lg font-semibold">Documentos cargados</h2>
          <table className="min-w-full divide-y divide-gray-200 mt-2">
            <thead>
              <tr>
                <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
                <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Proyecto</th>
                <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Código Doc.</th>
                <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Emitido por</th>
                <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Título</th>
                {/*encabezados.map((encabezado, index) => (
                  <th key={index} className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {encabezado}
                  </th>
                ))*/}
                <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Adjuntos</th>
                <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Comentarios</th>
                {/*<th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Archivos</th>*/}
                <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {documentosCargados.map((doc, index) => {
  // Concatenar los valores de los nivelesasdas
  const concatenatedLevels = niveles.map(nivel => doc.selectedValues[nivel.nivel_id] || '').join('-');

  return (
    <tr key={index}>
      <td className="px-2 py-1 text-sm text-gray-500">{index + 1}</td>
      <td className="px-2 py-1 text-sm text-gray-500">{doc.proyecto.split(' ').slice(1).join(' ')}</td>
      <td className="px-2 py-1 text-sm text-gray-500">
        {`${doc.codigo}-${concatenatedLevels}-REV${doc.revision}-V${doc.version}-${doc.correlativo}`}
      </td>
      <td className="px-2 py-1 text-sm text-gray-500">{doc.username}</td>
      <td className="px-2 py-1 text-sm text-gray-500">{doc.titulo}</td>
      <td className="px-2 py-1 text-sm text-gray-700">
        <button onClick={() => openModal(doc.archivos)}>
          <Paperclip className="w-5 h-5" />
        </button>
      </td>
      <td className="px-2 py-1 text-sm text-gray-500">{doc.comentarios}</td>

      <td className="px-2 py-1 text-sm text-gray-500">
        <button
          onClick={() => handleEditDocument(index)}
          className="text-yellow-500 hover:text-blue-600"
        >
          <Edit className="w-5 h-5" />
        </button>
        <button
          onClick={() => handleRemoveDocument(index)}
          className="text-red-500 hover:text-red-600"
        >
          <TrashIcon className="w-5 h-5" />
        </button>
      </td>
    </tr>
  );
})}
            </tbody>
          </table>
  
          <div className="w-full flex justify-center mt-4">
  
  <div className="flex space-x-4">
    <button
      onClick={enviarDatos}
      className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    >
      {cargarTexto}
    </button>
    <button
      onClick={() => {
        setProyectoSeleccionado('');
        setTituloDocumento('');
        setCorrelativo('');
        handleRemoveAllFiles();
        setRevision('');
        setComentarios('');
        setArchivos([]);
        setSelectedValues({});
        setArchivosComplementarios([]);
        setLabelMessage('');
        setIsSubVersion(0);
      }}
      className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
    >
      Reiniciar Carga
    </button>
  </div>
  
</div>

        </div>
        
      )}
       {modalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
    <div className="bg-white p-4 rounded shadow-lg w-1/2 max-w-lg">
      <h2 className="text-xl font-bold mb-4">Archivos Cargados</h2>
      {selectedFiles.length > 0 ? (
        <ul className="list-disc list-inside mb-4">
          {selectedFiles.map((file, index) => (
            <li key={index} className="mb-2">
              <a
                href={URL.createObjectURL(file)}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {file.name}
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <p>No hay archivos para mostrar.</p>
      )}
      <button
        onClick={closeModal}
        className="bg-red-500 text-white px-4 py-2 rounded mt-4"
      >
        Cerrar
      </button>
      
    </div>

  </div>
)}
{responseMessage && (
        <div className={`p-4 rounded-md  ${responseMessage.includes('Error') ?  'bg-red-200 text-red-800':  'bg-green-200 text-teal-800'}`}>
          {responseMessage}
        </div>
      )}
    </div>
    
  );
};

export default FormularioSubidaDocumentos;
